import React from 'react';
//import usePageTracking from "../hooks/usePageTracking"
import usePageTrackingGA4 from "../hooks/usePageTrackingGA4"

export default function GAWrapper({ children }) {
  //usePageTracking()
  usePageTrackingGA4()
  return <>
    {children}
  </>;
}
