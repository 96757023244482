import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import searchIcon from '../assets/search-24px.svg';
import searchClose from '../assets/icon-blackx.svg';

const SearchContainer = styled.div`
    width: ${props => (props.open ? "100%" : "60px")};
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-transition: all .15s;
	-moz-transition: all .15s;
    transition: all .15s;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box; 
    background: ${props => (props.open ? "#fff" : "#f8f8f8")};
`;

const SearchForm = styled.form`
    width: ${props => (props.open ? "calc(100% - 70px)" : "auto")};
    position: fixed;
    justify-content: flex-end;
    right:0;
    top:0;
    outline: none;
    display:none;
    @media (min-width: 769px) {
      display:flex;
    }
`;

const SearchInput = styled.input`
	border: none;
	font-family: soleil;
    font-size: 1em;
    font-weight:100;
    height: 56px;   
    outline: none;
    overflow:hidden;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box; 
    width: ${props => (props.open ? "calc(100% - 107px)" : 0)};
    background-color: ${props => (props.open ? "#FFF" : "#f8f8f8")};
    display: ${props => (props.open ? "inline-block" : "none")};
`;

const SearchButton = styled.button` 
    width: 60px;
    height: 56px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0;
    outline:0;
    background-color: ${props => (props.open ? "#ffffff" : "#f8f8f8")};
`;

const CloseButton = styled.button` 
    width: 60px;
    height: 56px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0;
    outline:0;
    background-color: ${props => (props.open ? "#ffffff" : "#f8f8f8")};
    display: ${props => (props.open ? "flex" : "none")};
`;

const SearchBar = (props) => {

    let history = useHistory();
    const [search, setInput] = useState("");
    const [open, setOpen] = useState(false);
    const searchField = useRef(null);

    useEffect(() => {
        if (open) {
            searchField.current.focus();
        }
    }, [open])

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (search.length > 2 && open) {
            history.push(`/search?q=${search}`)
            setInput("");
            setOpen(!open);
        }
    }

    const handleClick = () => {
        if (!open) {
            setOpen(true)
        }
        if (search.length > 2 && open) {
            setInput("")
            setOpen(false)
            history.push(`/search?q=${search}`)
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <SearchForm onSubmit={handleSubmit} open={open}>
            <SearchContainer open={open}>
                <SearchButton onClick={handleClick} open={open}>
                    <img src={searchIcon} style={{ width: "20px" }} alt="Search TOBE" />
                </SearchButton>
                <SearchInput
                    value={search}
                    open={open}
                    onChange={e => setInput(e.target.value)}
                    placeholder="Search"
                    ref={searchField}
                />
                <CloseButton open={open} onClick={handleClose}><img style={{ width: "16px" }} alt="Close Search" src={searchClose} /></CloseButton>
            </SearchContainer>
        </SearchForm>
    );
};

export default SearchBar;