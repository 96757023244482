import React, {useEffect} from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom';
import closeButton from '../assets/icon-blackx.svg'
import { useAuth0 } from "../react-auth0-spa";
import {config} from '../utils/constants'


const compare = (a, b) => {
    const bandA = a.displayName.toUpperCase();
    const bandB = b.displayName.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return comparison;
}

const ListOverlay = styled.div`
    position: fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index: 9999998;
    background: #fff;
    overflow-y: scroll;
    padding: 5% 0;
`

const List = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
`

const ListItem = styled(NavLink)`
    color: #D8292F;
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;
    font-size: 50px;
    line-height: 1.6em;
    letter-spacing: .02em;
    text-decoration: none;
    &:first-child  {
        display: none;
    }
`

const CloseButton = styled.button`
    position: fixed;
    top:40px;
    right:44px;
    z-index: 9999999;
    width: 50px;
    height: 50px;
    padding: 11px;
    background: #fff;
    border: none;
    cursor: pointer;
`

export default function ClientList({useLockBodyScroll, setClientsVisible}) {

    const { clientList, handleSetClientList, getTokenSilently } = useAuth0();
    

    useEffect(()=>{
        const fetchData = async() => {
            const token = await getTokenSilently()
            if(clientList.length < 1){
                await axios.get(`${config.API_URL}/clients/list`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(function (response) {
                    const sortedClients = response.data.sort(compare)
                    handleSetClientList(sortedClients)
                })
                .catch(function (error) {
                }); 
            }
        }
        fetchData()
        
    },[clientList.length, handleSetClientList])



    useLockBodyScroll();

    return (

        <ListOverlay>
            <CloseButton onClick={()=>setClientsVisible(false)}><img alt="close client list" src={closeButton}/></CloseButton>
            <List>
            {
                clientList && clientList.map(client=>
                <ListItem onClick={()=>setClientsVisible(false)} to={`/client/${client.clientName.replace(/'/g, "_").replace(/ /g, "__")}`} key={client.clientName}>
                    {client.displayName}
                </ListItem>)
            }
            </List>
        </ListOverlay>

    )
}
