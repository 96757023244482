import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components'
import { config } from '../utils/constants';


const ResultImage = styled.div`
    padding-bottom: 81%;
    height: 0%;
    overflow: hidden;
    background-image: url(${props => props.bgImage});
    background-size: cover;
    background-position: center center;
    -webkit-transition: all .25s;
	-moz-transition: all .25s;
    transition: all .25s;
    opacity: 1;
`

const ResultTitle = styled.h2`
    margin: 1em 0 0;
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1.5em;
    font-size: 20px;
`

const Result = styled(Link)`
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 30% auto;
    margin: 0 0 30px;
    padding: 0;
    text-decoration: none;
    color: #222;
    -webkit-transition: all .25s;
	-moz-transition: all .25s;
    transition: all .25s;
    opacity: 1;
    &: hover ${ResultImage} {
        opacity: .5;
    }
    @media (min-width: 768px) {
        grid-template-columns: 240px auto;
        display: flex;
        flex-direction: column;
        grid-column-gap: 60px;
        margin: 0;
    }
`

const RelatedEntry = ({entry}) => {

    const [imageBG, setImageBG] = useState();

    useEffect(()=>{
        //const environment = (process.env.NODE_ENV === 'development') ? 'https://www.donegertobe.com' : ''
        const environment = config.IMG_SRV
        const imageSrc = entry.fields.image ? entry.fields.image.fields.file.url : `${environment}${entry.fields.imageUrlThumb}`
        setImageBG(`${imageSrc}?w=400`)

    },[entry.fields.image, entry.fields.imageUrlThumb])

    return (
        <Result to={{
            pathname:`/great-ideas/${entry.fields.slug}`,
            id: entry.sys.id
        }}>
            <ResultImage bgImage={imageBG}/>
            <ResultTitle>{entry.fields.title}</ResultTitle>
        </Result>
    );
};

export default RelatedEntry;