//fetch standard article
import { useEffect, useState } from 'react'
import axios from 'axios'
import { config } from '../utils/constants'

export default function usePressQuery() {

  const [press, setPress] = useState();
  const [pressLoading, setPressLoading] = useState(true)


  useEffect(() => {
    setPressLoading(true)


    axios.get(`${config.API_URL}/content/getPress`)
      .then(function (response) {
        setPress(response.data);
        setPressLoading(false)
      })

  }, [])

  return { press, pressLoading }
}