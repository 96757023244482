import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth0 } from "../../react-auth0-spa";
import { useHistory, useLocation } from "react-router-dom";
import { Link as AnchorLink } from 'react-scroll'
import { NavLink } from 'react-router-dom';
import logo from '../../assets/structure/logo-donegertobe.svg'
import loginCircle from '../../assets/fos-fullcircle3.svg'
import loginCircleMob from '../../assets/fos-cutcircle.svg'

const Nav = styled.nav` 
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  background: #FFF;
  left: 0;
  right: 0;
`;

const NavContainer = styled.nav` 
  display: flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 1150px;
  position: relative;
`;

const NavLinksContainer = styled.nav` 
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  display:flex;
  position: absolute;
  right: 0;
  top: -20px;
  height: calc(100% + 40px);

  @media (min-width: 768px) {
    top: 0;
    right: 0;

    padding-top: 20px;
    padding-right: 0px;
    margin-right: -20px; 
    height: 106px;
  }

`;

const NavItem = styled(AnchorLink)`
  font-size: 14px;
  letter-spacing: .107em;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  text-decoration: none;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  transition: all .25s;
  display: none;
  opacity: ${props => props.visible ? 1 : 0};

  @media (min-width: 768px) {
    display: flex;
  }
  &.navbar__link--active {
    color: #d8292F;
  }
  &:hover {
    color: #d8292F;
  }


`;

const LoginButton = styled.button`
  position: relative;
  display: flex!important;
  opacity: 1;
  border: none; background: none;
  font-size: 14px;
  letter-spacing: .107em;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  text-decoration: none;
  height: 56px;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  width: 160px;
  & > img {
    position: absolute;
    width: 100%;
    max-width: 120px;
    margin-top: 0;
  }  
  @media (max-width: 767px) {
    __width: 136px;
    ___margin-right: 15px;

    width: 156px;
    margin-right: 0px;
    height: 90px;
    padding-top: 0;
    & > img {
      max-width: 125px;
      __margin-top: 5px;
    }  
  }
  `;

//const NavLogo = styled(AnchorLink)`
const NavLogo = styled.div`
  xxxflex: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  
  @media (min-width: 768px) {
    padding-left: 50px;
  }

  @media (min-width: 1024px) {
    padding-left: 10%;
  }

  @media (min-width: 1280px) {
    padding-left: 0;
  }
`;




const Logo = styled.img`
    width: 120px;
    padding: 22px 0 22px;
    transition: all 300ms ease-out;
    height: auto;
    cursor: ${props => !props.scrolled ? `unset` : `pointer`};
    @media (min-width: 768px) {
      width: 274px;
    padding: 50px 0 30px;
      width: ${props => !props.scrolled ? `650px` : `274px`};
      padding: ${props => !props.scrolled ? `205px 0 60px` : `50px 0 30px`};
    }
    @media (min-width: 1024px) {
      width: ${props => !props.scrolled ? `800px` : `274px`};
      padding: ${props => !props.scrolled ? `205px 0 60px` : `50px 0 30px`};
    }

    @media (min-width: 1280px) {
      width: ${props => !props.scrolled ? `1150px` : `274px`};
      padding: ${props => !props.scrolled ? `205px 0 60px` : `50px 0 30px`};
    }
`

/* const ImgLoginMob = styled.img`
  @media (min-width: 768px) { {
    display: none;
  }
` */

/* const ImgLogin = styled.img`
display: none;
  @media (min-width: 768px) { {
    display: block;
  }
` */

const ImgLogin = styled.img`


  `

const sections = [
  { index: 0, href: '#about', label: 'About' },
]

const PrePayNavBar = ({ scrolled, setScrolled }) => {
  //const [itemsVisible, setItemsVisible] = useState(false)

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const history = useHistory();
  const location = useLocation()
  //const [scrolled, setScrolled] = useState(false);

  //
  //const [isShrunk, setShrunk] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
    const onScroll = () => {
      setScrolled((scrolled) => {
        if (
          !scrolled &&
          (document.body.scrollTop > 30 ||
            document.documentElement.scrollTop > 30)
        ) {
          return true;
        }

        if (
          scrolled &&
          document.body.scrollTop < 10 &&
          document.documentElement.scrollTop < 10
        ) {
          return false;
        }

        return scrolled;
      });
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);


  /*   useEffect(() => {
      if (isAuthenticated) {
        history.push("/great-ideas");
      }
  
  
    }, [isAuthenticated, history, location]) */

  const links = sections.map(({ index, label }) => <NavItem visible={location.pathname === "/" ? 1 : 0} /* intro={intro ? 1 : 0} */ key={`nav-${index}`} activeClass="prePayActive" className={`test${index + 1}`} to={`about`} spy={true} smooth={true} duration={500} href="https://www.donegertobe.com" rel="nofollow">{label}</NavItem>)


  return (
    <>
      <Nav>
        <NavContainer>



          <NavLogo /* smooth={`true`} duration={500} to={`top`} href="https://www.donegertobe.com" rel="nofollow" */ onClick={() => window.scrollTo(0, 0)}>
            <Logo src={logo} scrolled={scrolled} alt="DONEGER | TOBE" width="1150" height="122" />
          </NavLogo>

          <NavLinksContainer>
            {links}

            <LoginButton onClick={() => loginWithRedirect({})} >
              <span>Log In</span>
              <ImgLogin alt="log in" src={loginCircle} />
              {/*  <ImgLoginMob alt="log in" src={loginCircleMob} /> */}
            </LoginButton>

          </NavLinksContainer>




        </NavContainer>
      </Nav>

    </>
  );
}

export default PrePayNavBar;


