import React from 'react';
import { useBookmarks } from '../contexts/bookmarks-context';

import styled from "styled-components"
import { sections } from '../utils/constants'
import { replaceAmps } from '../utils/functions'

const SaveShareInfo = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 20px 0 0;
    display: flex;
    justify-content: flex-end;
    & > span > * {
        border-right: 1px solid rgba(0, 0, 0, 0.9);
    }
    & > span > *:last-child {
        border-right: none;
        padding-right: 0!important;
    }
`;

const ButtonSave = styled.button`
font-size:12px;
    transition: all 250ms;
    border: none;
    background: none;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-family: soleil;
    cursor: pointer;
    padding: 0 1em;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
        color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: default;
        color: rgba(0,0,0,.3)!important;
    }
`;

const ButtonShare = styled.a`
display: inline-block;
    transition: all 250ms;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-family: soleil;
    cursor: pointer;
    font-size: 12px;
    padding: 0 1em;
    line-height: 1em;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
        color: rgba(0, 0, 0, 0.3);
    }
`

const ButtonDownload = styled(ButtonShare)`
    
 
`



const SaveShare = ({ entryId, slug, section, title, download = `` }) => {

    //const { bookmarks, handleUpdateBookmarks } = useAuth0();
    const { bookmarks, handleUpdateBookmarks } = useBookmarks()

    const handleAdd = (id) => {
        handleUpdateBookmarks(id)
    }



    return (
        <SaveShareInfo>
            {(section || download) &&
                <span>
                    {download.endsWith(".pdf") && <ButtonDownload href={download.trim()} target="_blank">download</ButtonDownload>}
                    {slug && <ButtonShare href={`mailto:?subject=${replaceAmps(title)}&body=${replaceAmps(title)}%0D%0Ahttps://www.donegertobe.com/${sections[section].value}/${slug}`}>share</ButtonShare>}
                    {(entryId && Array.isArray(bookmarks)) && <ButtonSave saved={bookmarks.includes(entryId)} disabled={bookmarks.includes(entryId) ? true : false} onClick={() => handleAdd(entryId)}>{bookmarks.includes(entryId) ? `saved` : `save`}</ButtonSave>}
                </span>
            }
        </SaveShareInfo>
    );
};

export default SaveShare;