import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components'

const EntryP = styled.p`
  margin: 0;
  line-height: 1.30em;
  font-size: 16px;
  @media (min-width: 600px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 19px;
  }
`

/* const EntryH1 = styled.p`
  font-family: soleil, sans-serif;
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  color: #222;
  text-transform: uppercase;
  letter-spacing: .03em;
  transition: color 150ms ease-out;
` */

const Bolded = styled.strong`
  font-weight: 500;
`

const Anchor = styled.a`
  text-decoration: underline;
`

const Text = ({ children }) => <EntryP>{children}</EntryP>;
//const Heading = ({ children }) => <EntryH1>{children}</EntryH1>;

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bolded>{text}</Bolded>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    //[BLOCKS.HEADING_1]: (node, children) => <Heading>{children}</Heading>,
    [INLINES.HYPERLINK]: (node, children) => {
        return <Anchor href={node.data.uri}>{children}</Anchor>;
      },
  },

};


export const MicroEntryText = (props) => {
    return documentToReactComponents(props.text, options); // -> <p>Hello world!</p>
}
