import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ScrollLock from 'react-scrolllock'
import axios from 'axios'
import { validateEmail } from '../../utils/validateEmail'
import { config } from '../../utils/constants'

const OverlayScreen = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background: rgba(0,0,0,.3);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Modal = styled.div`
    width: 90%;
    max-width: 1150px;
    border: 1px solid #222;
    height: auto;
    background: white;
    z-index: 99;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    @media (min-width: 768px) {
        width: 90%;
        height: 576px;
    }
`

const ModalClose = styled.div`
    position: absolute;
    right: 25px;
    top: 16px;
    z-index: 100;
    font-family: soleil, sans-serif;
    font-size: 9px;
    letter-spacing: .08em;
    line-height: 1em;
    cursor: pointer;
    color: ${props => props.mode === 'success' ? '#FFF' : '#222 '};
    @media (min-width: 768px) {
        font-size: 13px;
        top: 26px;
        right: unset;
        left: 60px;
    }
`


const ModalInfo = styled.div`
    padding: 50px 0 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #D8292F;
    @media (min-width: 768px) {
        padding: 0 65px 0 0;
        margin-bottom: 0px;
        border-bottom: none;
        border-right: 1px solid #D8292F;
        height: 100%;
        display: flex;
        align-items: center;
        & > div {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
    }
`

const ModalBody = styled.div`
    display: grid;
    padding: 0 30px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        padding: 100px 60px 0;
        height: 490px;
    }

`

const ModalTitle = styled.h1`
    font-family: freight-big-pro,serif;
    font-weight: 300;
    font-style: normal;
    font-size: 20px;
    letter-spacing: .06em;
    line-height: 1.17em;
    color: #D8292F;
    @media (min-width: 768px) {
        font-size: 40px;
    }
`

const ModalText = styled.p`
    font-family: freight-big-pro,serif;
    font-weight: 300;
    font-style: normal;

    font-size: 18px;
    letter-spacing: .04em;
    line-height: 1.32em;
    @media (min-width: 768px) {
        font-size: 25px;
    }
`

const ModalForm = styled.div`

    @media (min-width: 768px) {
        padding: 0 0 0 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`


const FormText = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid #707070;
  font-size: 10px;
  line-height: 2.5em;
  letter-spacing: .06em;
  color: #222;
  font-family: soleil, sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 0;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: .6;
  }
  &::placeholder {
    color: #707070;
  }
  @media (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 15px;
  }  
`

const ModalButton = styled.div`
    margin: 2em 0 2.5em;
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
    
    @media (min-width: 768px) {
        margin: 0;
        padding-right: 60px;
        justify-content: flex-end;
      }  
`

const SubmitButton = styled.button`
    cursor: pointer;
    color: #D8292F;
    text-transform: uppercase;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: .08em;
    font-size: 10px;
    border: 1px solid #D8292F;
    border-radius: 18px;
    background-color: #fff;
    padding: .5em 2em;
    margin: 0;
    &:disabled {
        opacity: .7!important;
        cursor: no-drop;
    }
    &:hover:enabled {
        transition: all 250ms;
        background: #D8292F;
        color: #FFF;
    }

    &:focus {
        outline: none;
    }
    @media (min-width: 768px) {
        font-size: 14px;
        padding: .5em 3em;
    }
`;

const ModalSuccess = styled.div`
    position: absolute;
    background: #D8292F;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

`

const ModalSuccessTitle = styled.h1`
    font-family: freight-big-pro,serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.06em;
    font-size: 25px;
    color: #FFF;
    text-align: center;
    line-height: 1em;
    @media (min-width: 768px) {
        font-size: 60px;
    }
`

const ModalSuccessText = styled.p`
    font-family: freight-big-pro,serif;
    font-weight: 300;
    font-style: italic;  
    letter-spacing: 0.06em;
    font-size: 18px;
    text-align: center;
    margin: 0;
    color: #FFF;
    @media (min-width: 768px) {
        font-size: 40px;
    }
`

const ModalSubtitle = styled.div`
    font-size: 12px;
    margin: 1em 0;
    text-transform: uppercase;
    font-family: soleil, sans-serif;
    letter-spacing: .06em;
    line-height: 1em;
    @media (min-width: 768px) {
        font-size: 15px;
    }

`

//const ContactModal = ({open, setOpen, captureType, origin, title, successMsg="We’ll meet you in your inbox to schedule a time."}) => {
const ContactModal = ({ open, setOpen, captureType, originSource, originDetail, title, text, successMsg = "We’ll see you in your inbox." }) => {
    const modal = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)
        return () => {
            document.removeEventListener("mousedown", handleClick)
        };
    }, []);

    const handleClick = e => {
        if (modal.current.contains(e.target)) {
            return;
        }
        handleClose()
    };

    const handleClose = (e) => {
        document.removeEventListener("mousedown", handleClick)
        //setIsLocked(false)
        setOpen(false)
    }

    const [name, setName] = useState(``)
    const [email, setEmail] = useState(``)
    const [company, setCompany] = useState(``)

    const [confirmation, setConfirmation] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (name && validateEmail(email)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [name, email])


    const submitForm = async (e) => {

        setIsDisabled(true)
        setIsLoading(true)

        const captureObject = {
            email: email,
            captureType: captureType,
            //origin: origin,
            originSite: `DONEGER|TOBE`,
            originSource: originSource,
            originDetail: originDetail,

        }
        if (name) captureObject.name = name
        if (company) captureObject.company = company;

        axios.post(`${config.API_URL}/request/pdfRequest`, captureObject).then(function (response) {
            setConfirmation(true)
        })
    }



    return (


        <OverlayScreen>
            <ScrollLock isActive={open} accountForScrollbars={true}></ScrollLock>
            <Modal ref={modal}>
                {confirmation &&
                    <ModalSuccess>
                        <div>
                            <ModalSuccessTitle>Thank you!</ModalSuccessTitle>
                            <ModalSuccessText>{successMsg}</ModalSuccessText>
                        </div>
                    </ModalSuccess>
                }


                <ModalBody>
                    <ModalInfo>
                        <div>
                            <ModalTitle>{title}</ModalTitle>
                            <ModalText>{text}</ModalText>
                        </div>
                    </ModalInfo>
                    <ModalForm>
                        <div>
                            <ModalSubtitle>Contact Info</ModalSubtitle>
                            <FormText disabled={isLoading} placeholder="Name" type="text" required value={name} onChange={e => setName(e.target.value)} />
                            <FormText disabled={isLoading} placeholder="Email" type="email" required value={email} onChange={e => setEmail(e.target.value)} />
                            <FormText disabled={isLoading} placeholder="Company" type="text" value={company} onChange={e => setCompany(e.target.value)} />
                        </div>
                    </ModalForm>
                </ModalBody>

                <ModalButton>
                    <SubmitButton disabled={isDisabled} onClick={() => submitForm()}>Download</SubmitButton>
                </ModalButton>

                <ModalClose mode={confirmation ? 'success' : 'default'} onClick={() => handleClose()}>x close</ModalClose>
            </Modal>
        </OverlayScreen>

    );
};

export default ContactModal;