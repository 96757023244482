import React from 'react';
import ProgressiveImage from "react-progressive-graceful-image"
import styled from 'styled-components';


const Image = styled.img`
    transition: all .25s linear;
    opacity: ${props => (props.loading === `true` ? 0.5 : 1)};
    filter: ${props => (props.loading === `true` ? 'blur(10px)' : 'blur(0)')};
    clip-path: inset(0);
`;

const Entry = styled.div`
    
`;


const ImageSet = ({imageSet}) => {
    return (
        <Entry>
            {
                imageSet.map((image, i)=>
                    <ProgressiveImage 
                        key={i}
                        delay={i === 0 ? 500 : 750} 
                        src={`${image}`}
                        placeholder={`${image}?w=10`}
                        srcSetData={{
                            srcSet: `${image}?w=480 480w, ${image}?w=900 900w, ${image}?w=1600 1600w`,
                            sizes: '(max-width: 480px) 100vw, (max-width: 900px) 100vw, 1600px'
                        }}>
                    {(src, loading, srcSetData) => (        
                        <Image
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            src={src}
                            alt={src}
                            loading={`${loading}`} 
                        />
                    )}
                    </ProgressiveImage>
                )
            }
        </Entry>
    );
};

export default ImageSet;
