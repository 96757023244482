import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components'

const CallOutText = styled.p`
  text-decoration: none;
  color: #D8292F!important;
  font-family: freight-big-pro, serif!important;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  line-height: 1.3em;
  letter-spacing: .05em;
`;

const Bold = ({ children }) => <span style={{ fontWeight: 500 }}>{children}</span>;
const CallOut = ({ children }) => <CallOutText>{children}</CallOutText>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_5]: (node, children) => <CallOut>{children}</CallOut>,
    [INLINES.HYPERLINK]: (node, children) => {
      // If you are using contenful.js sdk, the referenced entry is resolved
      // automatically and is available at `node.data.target`.
      return <a style={{ color: "#D8292F", fontFamily: "inherit" }} href={node.data.uri}>{children}</a>;
    },
  },


};



export const RichText = (props) => {


  return documentToReactComponents(props.text, options); // -> <p>Hello world!</p>
}
