export function getAbsolutePath(url) {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname; 
/*     const regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)(\/.*)/;
    const match = url.match(regex);
    return match ? match[2] : '/'; */

    /* const xxx = url.replace(/^.*\/\/[^\/]+/, '')
    return xxx */
/*     const regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)(\/.*)/;
    const match = url.match(regex);
    return match ? match[2] : '/'; */
   // return url

  }
  