
import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import NotSubscribed from "../views/NotSubscribed"


//const PrivateRoute = ({ component: Component, path, ...rest }) => {
const PrivateRoute = ({ comp: Component, path, ...rest }) => {
  const { user, loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { search } = useLocation()
  useEffect(() => {

    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {

      const targetUrl = search ? window.location.pathname + search : window.location.pathname;
      await loginWithRedirect({
        //appState: { targetUrl: window.location.pathname }
        appState: { targetUrl: targetUrl }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path, search]);

  const render = props => {
    if (isAuthenticated === true) {
      return <Component {...props} />
    } else {
      return null
    }
  }

  if (user && path) {
    if (user[`https://tobereport/`].memberships.tobetdg === true) return <Route path={path} render={render} {...rest} />
    if (user[`https://tobereport/`].memberships.alacarte === true) {


      const acceptableRoutes = [...user[`https://tobereport/`].subscription, `search`, `great-ideas`, `custom-work`, `travel-guides`, 'home', 'the-weekly']
      //const acceptable = acceptableRoutes ? acceptableRoutes.some(route => path.includes(route)) : true
      const currentSection = path.split("/").filter(rte => rte !== "")[0]

      const acceptable = acceptableRoutes.find(rte => rte === currentSection)

      const hasAccess = user[`https://tobereport/`].memberships.alacarte === true && acceptable

      return hasAccess ? <Route path={path} render={render} {...rest} /> : <NotSubscribed path={path} />
    }




  } else {
    return null //needed?
  }

};

export default PrivateRoute;