import React, { useEffect} from 'react';
import styled from 'styled-components'
import { Link } from "react-router-dom"
import FadeIn from "react-fade-in";

const Container = styled.div`
    width: 100%;
    margin: 50px auto 140px;
    max-width: 1080px;
    & > p {
        font-size: 18px;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
`

const Privacy = () => {
    document.title = "Privacy";
    useEffect(()=>{
        window.scrollTo(0, 0) 
    },[]);

    return (
        <FadeIn>
        <Container>
            <p><b>Privacy Policy</b></p>

            <p>DONEGER | TOBE, a division of Henry Doneger Associates, Inc. ("Company"), provides users ("Users", "You" or "Your") of the web pages located at <Link to="/">https://www.donegertobe.com</Link> (the "Site") with a platform for the discovery of information relating to consumer insights and incorporates certain features, functions or services on the Site (collectively, the "Service"). Much of the Site and the Services are only available to Users who become members ("Members"). If you have any questions concerning this privacy policy, please email us at <a href="mailto:websitehelp@doneger.com">websitehelp@doneger.com</a>.</p>

            <p><b>Information Collection and Use by Company.</b></p>

            <p>Company collects User submitted information such as name, email address, and company affiliation to authenticate Users and to send notifications to those Users relating to the Service.</p>

            <p>Company also logs non-personally-identifiable information including IP address, profile information, aggregate user data, and browser type, from users and visitors to the Site. This data is used to manage the Site, track usage and improve the Services.</p>

            <p>Company reserves the right to share all information You provide with any third party as Company may determine from time to time.</p>

            <p>Additionally, the persons with whom we may share such information may have substantially different policies regarding such information than Company may have, and we do not assume any obligation to determine such persons' policies or to advise You of what their policies might be. Company has no obligation to inform You of the identities of the persons with whom Your information may be shared.</p>

            <p><b>Use of Cookies</b></p>

            <p>Company uses cookies to store Users' preferences and to record session information, for purposes including allowing Users to save collections of gallery images to their profile. You may be able to configure Your browser to accept or reject all or some cookies, or notify You when a cookie is set -- each browser is different, so check the "Help" menu of Your browser to learn how to change Your cookie preferences.</p>

            <p><b>Links</b></p>

            <p>Company contains links to sites. Company is not responsible for the privacy policies and/or practices on other sites. When linking to another site a user should read the privacy policy stated on that site. Our privacy policy only governs information collected on Company.</p>

            <p><b>Security</b></p>

            <p>Member accounts are secured by Member-created passwords. Company takes precautions to ensure that Member account information is kept private. We use reasonable measures to protect Member information that is stored within our database, and we restrict access to Member information to those employees who need access to perform their job functions, such as our customer service personnel and technical staff. Please note that we cannot guarantee the security of Member account information.</p>

            <p>Unauthorized entry or use, hardware or software failure, and other factors may compromise the security of Member information at any time.</p>

            <p><b>Changes in Our Privacy Policy</b></p>

            <p>From time to time we may make changes to our privacy policy. If we make changes, we will post them on our site to make Users aware of what the changes are so Users will always be aware of what information we collect, how we use it, and when we may disclose it. A User is bound by any minor changes to the policy when such User uses the site after those changes have been posted. If, however, we are going to use Users' personally identifiable information in a manner materially different from that stated at the time of collection we will notify by posting a notice on the Site for 30 days.</p>

            <p><b>Contacting the Site</b></p>

            <p>If you have any questions about this Privacy Policy, the practices of this Site, or your dealings with this Site, please contact us at: (212)564-1266.</p>
        </Container>
        </FadeIn>
    );
};

export default Privacy;