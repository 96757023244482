import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import ScrollLock from 'react-scrolllock'
import holiday24 from '../../assets/home/holiday24.png'


const OverlayScreen = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background: rgba(255,255,255,.75);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Modal = styled.div`
    width: 90%;
    max-width: 790px;

    z-index: 99;
    position: relative;
    cursor: pointer;
    @media (min-width: 600px) {
        width: 100%;
    
    }
`


//const ContactModal = ({open, setOpen, captureType, origin, title, successMsg="We’ll meet you in your inbox to schedule a time."}) => {
const HolidayModal = ({ open, setOpen }) => {
    const modal = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)
        return () => {
            document.removeEventListener("mousedown", handleClick)
        };
    }, []);

    const handleClick = e => {
        if (modal.current.contains(e.target)) {
            return;
        }
        handleClose()
    };

    const handleClose = (e) => {
        document.removeEventListener("mousedown", handleClick)
        //setIsLocked(false)
        setOpen(false)
    }




    return (


        <OverlayScreen>
            <ScrollLock isActive={open} accountForScrollbars={true}></ScrollLock>
            <Modal ref={modal}>
                <img src={holiday24} alt="HAPPY HOLIDAYS" onClick={() => setOpen(false)} />

            </Modal>
        </OverlayScreen>

    );
};

export default HolidayModal;