import React, {useState, useEffect} from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import PrePayNavBar from '../components/PrePayNavBar'
import arrowRight from '../assets/arrow-right-grey.svg'
import {config} from '../utils/constants'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    left:0;
    position: absolute;
    top:0;
    bottom:0;
    
`

const ForgotHeadline = styled.h1`
    font-size: 30px;
    letter-spacing: .06em;
    @media (min-width: 768px) {
        font-size: 60px;
    }
`

const ForgotText = styled.p`
    font-size: 15px;
    margin: 1em 0 2em;
    letter-spacing: .06em;
    @media (min-width: 768px) {
        font-size: 18px;
    }
`

const FooterEmailInput = styled.input`
    border:none;
    display: flex;
    flex-grow: 1;
    height: 40px;
    letter-spacing: .06em;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 12px;
    background: #f8f8f8;
    
    &:disabled {
        background: none;
    }
    @media (min-width: 1024px) {
        font-size: 17px;
    }    
    
`

const FooterButton = styled.button`
    border: none;
    padding: 0;
    width: 8px;
    background: #fff;
    cursor: ${props => ((!props.subscribed && !props.disabled) ? `pointer` : `not-allowed`)};
    transition: opacity 250ms ease-out;
    opacity: ${props => (!props.disabled ? 1 : .5)};
`

const FooterForm = styled.form`
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    margin: 0 10% 2.5em;
    justify-content: center;
`
const ForgotCap = styled.div`
    text-align: center;
`
const ForgotBack =  styled(Link)`
    font-size: 13px;
    font-style: italic;
    color: #222;
    text-decoration: none;
`


export default function ForgotPassword() {

    const resetPassword = (event) => {
        //alert(`forgot`)
        event.preventDefault()
        setIsDisabled(true)
        axios.post(`${config.API_URL}/changePw`, {    
            email
        }).then(function (response) {
            setSubmitted(true)
        })
    }

    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState()
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(()=>{
        const validEmail = validateEmail(email);
        if(validEmail){
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
    }, [email])

    const validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    return (
        <Container>
            <PrePayNavBar intro={true}/>

    
                {!submitted ? 
                    <ForgotCap>
                        <ForgotHeadline>Forgot password?</ForgotHeadline>
                        <ForgotText>Enter your email address below to reset your password.</ForgotText>
        

                        <FooterForm onSubmit={resetPassword}>
                            <FooterEmailInput  required type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
                            <FooterButton disabled={isDisabled}><img alt="submit" src={arrowRight}/></FooterButton>
                        </FooterForm>
                        <ForgotBack to={`/`}>Back To Site</ForgotBack>
                    </ForgotCap>
                :
                    <ForgotCap>
                        <ForgotHeadline>Please check your inbox</ForgotHeadline>
                        <ForgotText>Need more help? <a href="mailto:hello@doneger.com">Contact us</a> for further assistance.</ForgotText>
                        <ForgotBack to={`/`}>Back To Site</ForgotBack>
                    </ForgotCap>
                }
          
        </Container>
    )
}
