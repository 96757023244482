import React from "react"
import FadeIn from "react-fade-in"
import styled from 'styled-components';

import { CSSTransition } from 'react-transition-group'
import ContactModal from '../components/contact/ContactModal'

const Container = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1056px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 70vh;
    & span {
        cursor: pointer;
        color: #d8292F;
        text-decoration: none;
        border-bottom: 1px solid #d8292F;
    }
    & p {
        font-family: gopher, sans-serif;
        font-weight: 100;
        font-style: normal;
        letter-spacing: .05em;
        line-height: 1.6em;
        font-size: 22px;
        text-align: center;
        @media (min-width: 768px) {
            font-size: 26px;
        }  
        @media (min-width: 1024px) {
            font-size: 30px;
        }  
        @media (min-width: 1280px) {
            font-size: 37px;
        }   
    }
`

const NotSubscribedMsg = ({ msg, msg2, open, section, setOpen }) => {


    return (
        <FadeIn>
            <Container>
                <CSSTransition
                    in={open}
                    timeout={300}
                    classNames="contact-modal"
                    unmountOnExit
                >
                    {/* <ContactModal open={open} setOpen={setOpen} captureType={`member`} origin={`Not Subscribed - ${section}`} title={section} successMsg={`We’ll meet you in your inbox to discuss further.`}/> */}
                    <ContactModal open={open} setOpen={setOpen} captureType={`member`} originSource={`PPW`} originDetail={`Not Subscribed - ${section}`} title={section} successMsg={`We’ll meet you in your inbox to discuss further.`} />

                </CSSTransition>
                <div>
                    <p>{msg}</p>
                    {msg2}
                </div>
            </Container>
        </FadeIn>
    );
};

export default NotSubscribedMsg;


