import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {RichText} from '../../components/RichText'
import ImageSet from '../../components/ImageSet'
import {Tag} from "../../components/ui/FilterButton"
import {formatDate} from '../../utils/formatDate'
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'


const Entry = styled.div`
    display: grid;
    grid-template-columns: none ; 
    grid-template-rows: auto auto auto; 
    position: relative;
    margin-top: 45px;
    @media (min-width: 768px) {
        grid-template-columns: 50% 50%;
    }

`;


const EntryText = styled.div`
     align-self: flex-start;
    font-size: 16px;    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    row-gap: 30px;
    
    @media (min-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
        padding: 0 30px 0 45px;
        font-size: 19px; 
        position: sticky;
        top: 60px;
    }
    & > a {
        display: inline-block;
    }
    & p a {
        color: #222!important;
        text-decoration: underline;
    }
    & p:last-child {
        margin: 0;
    }
`;


const EntryTitle = styled.h1`
    font-size: 30px;
    letter-spacing: .05em;
    text-decoration: none;
    color: rgba(0,0,0,.9);
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;

    line-height: 1em;

    @media (min-width: 768px) {
        font-size: 60px;

    }
`;


const EntryImages = styled.div`
    
    @media (min-width: 768px) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
        padding: 0 45px 0 30px;
    }

`;
const EntryImage1 = styled.div`
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;

const EntryImage2 = styled.div`
    margin: 1em 0 0;
`;


const EntryInfo = styled.div`
    margin: 0 0 1em;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-transform: uppercase;

    @media (min-width: 768px) {
        margin: 0;
        font-size: 12px;
    }
`;


const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
`


const MicroArticle = ({entry, privatePath}) => {

    const [imgArray, setImageArray] = useState([]);
    const history = useHistory();
    const contentRef = useRef()
    const [eventListeners, setEventListeners] = useState();

    const isDesktop = useMediaQuery({
        query: '(min-width: 768px)'
    })
    
    useEffect(()=>{
        let imageArray = [];

        imageArray = [
            `${entry.fields.image.fields.file.url}`,
            ...(entry.fields.image2 ? [entry.fields.image2.fields.file.url] : []),
            ...(entry.fields.image3 ? [entry.fields.image3.fields.file.url] : []),
        ]; 

        setImageArray(imageArray)
        setEventListeners("init");
    },[entry.fields.image, entry.fields.image2])


    useEffect(() => {
        const pushRoute = (ev) => {
            ev.stopPropagation();
                ev.preventDefault();
            if(ev.srcElement.href){
                const link = ev.srcElement.href.replace(/^(?:\/\/|[^/]+)*\//, '')
                history.push(`/${link}`)
            } else {
                const nestedLink = ev.target.closest("a");     // Find closest Anchor (or self)
                let nestedFile =  nestedLink.getAttribute('href').replace(/^(?:\/\/|[^/]+)*\//, '')
                history.push(`/${nestedFile}`)
            }
        }
        if(eventListeners){
            const copyLinks = contentRef.current.querySelectorAll('.content p a')
            copyLinks.forEach((anc) => {
                let withinDonegerTobe = anc.href.indexOf("donegertobe.com/") //replace with donegertobe.com
                if (withinDonegerTobe !== -1) {
                    anc.addEventListener('click', pushRoute)
                }
            })
            return function cleanup() {
                copyLinks.forEach((anc) => {
                    anc.removeEventListener("click", pushRoute)
                })
            };
        }
    },[eventListeners, history]);

    const handleTagClick = (tag) => {
        history.push(`/custom/${privatePath}?tags=${tag}`)
    }

    return (
        <Entry>
          

            <EntryText>
            <EntryTitle>
                {entry.fields.title}
             </EntryTitle>
                <div className="content" ref={contentRef}>
                    {/* <EntryImageMob>
                        <ImageSet imageSet={[imgArray[0]]}/>
                    </EntryImageMob> */}
                    <RichText text={entry.fields.text}/>
                </div>
                <Tags>{entry.fields.tags.sort().map(tag=><Tag disabled={!isDesktop}  key={tag}  onClick={()=>handleTagClick(tag)}>{tag}</Tag>)}</Tags>
                <EntryInfo>
                    <span>{formatDate(entry.fields.date, 'MMMM d, yyyy')}</span>

                    
                </EntryInfo>
            </EntryText>
                                             
            <EntryImages>       
                <EntryImage1>
                    <ImageSet imageSet={[imgArray[0]]}/>
                </EntryImage1>
                {imgArray[1] && 
                    <EntryImage2>
                        <ImageSet imageSet={[imgArray[1]]}/>
                    </EntryImage2>
                }
                                {imgArray[2] && 
                    <EntryImage2>
                        <ImageSet imageSet={[imgArray[2]]}/>
                    </EntryImage2>
                }
            </EntryImages>
        </Entry>  
    );
};

export default MicroArticle;