import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BreadcrumbBack from '../assets/breadcrumb-back.svg'

const EntryBreadcrumb = styled(Link)`
    border-bottom: 1px solid #ccc;
    font-size: .7em; 
    color: rgba(0,0,0,.5);
    line-height: 3em;    
    width: 100%;
    display:inline-block;
    @media (min-width: 768px) {
        font-size: 1em; 
    }    
`

const EntryBreadcrumbLink = styled.span`
    display: inline-block;
    cursor: pointer;
`

const EntryBreadcrumbImg = styled.img`
    vertical-align: middle;
    width: 8px;
    margin-right: 1em;
    @media (min-width: 768px) {
        margin-right: 2em;
    }    
`


const BreadCrumb = ({ section, title, path, qString }) => {

    const returnPath = (qString && qString.startsWith("?q=")) ? ("/search" + qString) : (path + (qString ? qString : ""))

    return (
        <EntryBreadcrumb to={returnPath}>
            <EntryBreadcrumbLink>
                <EntryBreadcrumbImg src={BreadcrumbBack} />
                {path === '/home' ? 'Home' : (qString && qString.startsWith("?q=")) ? "Search" : section} {section && <span>/</span>} {title}
            </EntryBreadcrumbLink>
        </EntryBreadcrumb>
    );
};

export default BreadCrumb;