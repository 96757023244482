import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth0 } from "../react-auth0-spa";
import { useHistory, useLocation } from "react-router-dom";
import { Link as AnchorLink } from 'react-scroll'
import { NavLink } from 'react-router-dom';
//import logo from '../assets/logo-tobetdg.svg'
import logo from '../assets/structure/logo-donegertobe.svg'
//import loginCircle from '../assets/fos-cutcircle.svg'
//import loginCircle from '../assets/fos-fullcircle2.svg'

import loginCircle from '../assets/fos-fullcircle3.svg'

const Nav = styled.nav` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  height: 56px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background: #f8f8f8;
  left: 0;
    right: 0;
`;

const NavContainer = styled.nav` 
  display: flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLinksContainer = styled.nav` 
  justify-content: space-between;
  
  flex-flow: row wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  display:flex;
  @media (min-width: 768px) {
    display: flex;
    width: 170px;
  }

`;

const NavItem = styled(AnchorLink)`
  font-size: 14px;
  letter-spacing: .107em;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  text-decoration: none;
  height: 56px;
  align-items: center;
  border-right: 1px solid #ccc;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  -webkit-transition: all .25s;
	-moz-transition: all .25s;
  transition: all .25s;
  display: none;
  opacity: ${props => props.visible ? 1 : 0};
  xxxopacity: 1;
  @media (min-width: 768px) {
    display: flex;
  }
  &.navbar__link--active {
    color: #d8292F;
  }
  &:hover {
    color: #d8292F;
  }
  &:last-child{
    border-right:none;
  }
  &:first-child{
    border-left: 1px solid #ccc;
  }

`;

const LoginButton = styled.button`
  position: relative;
  display: flex!important;
  opacity: 1;
  border: none; background: none;
  font-size: 14px;
  letter-spacing: .107em;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  text-decoration: none;
  height: 56px;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;

  & > img {
    position: absolute;
    width: 100%;
    max-width: 120px;
  }  
  @media (max-width: 768px) {
    width: 136px;
    margin-right: 15px;
  }`;

const NavLogo = styled(AnchorLink)`
  flex: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  cursor: pointer;
  @media (min-width: 768px) {
    
    padding: 0 30px;
  }
`;

const NavLogoHome = styled(NavLink)`
flex: 60px 0 0;
display: flex;
align-items: center;
justify-content: center;
padding: 0 15px;
cursor: pointer;
@media (min-width: 768px) {
  
  padding: 0 30px;
}
`;


const Logo = styled.img`
  width: 140px;
`

const sections = [
  { index: 0, href: '#intel', label: 'Intel' },
  { index: 1, href: '#services', label: 'Services' },
  /*   { index: 2, href: '#clients', label: 'Clients' }, */
  { index: 3, href: '#news', label: 'News' }
]

const PrePayNavBar = ({ intro, homeLink = false }) => {
  const [open] = useState(false)
  //const [itemsVisible, setItemsVisible] = useState(false)

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const history = useHistory();
  const location = useLocation()

  useEffect(() => {
    if (isAuthenticated) {
      //history.push("/great-ideas");
      history.push("/mechandising");
    }


  }, [isAuthenticated, history, location])

  //const links = sections.map(({ index, label }) => <NavItem visible={location.pathname === "/" ? 1 : 0} /* intro={intro ? 1 : 0} */ key={`nav-${index}`} activeClass="prePayActive" className={`test${index+1}`} to={`test${index+1}`} spy={true} smooth={true} duration={500} href="https://www.donegertobe.com" rel="nofollow">{label}</NavItem>) 


  return (
    <>
      <Nav>
        <NavContainer>
          {homeLink ?
            <NavLogo smooth={`true`} duration={500} to={`test0`} href="https://www.donegertobe.com" rel="nofollow">
              <Logo src={logo} alt="DONEGER | TOBE" width="300" height="59" />
            </NavLogo>
            :
            <NavLogoHome to={`/`} href="https://www.donegertobe.com" rel="nofollow">
              <Logo src={logo} alt="DONEGER | TOBE" width="300" height="59" />
            </NavLogoHome>
          }
          <NavLinksContainer open={open}>
            {/* {links}    */}

            <LoginButton onClick={() => loginWithRedirect({})} >
              <span>Log In</span>
              <img alt="log in" src={loginCircle} />
            </LoginButton>

          </NavLinksContainer>
        </NavContainer>
      </Nav>

    </>
  );
}

export default PrePayNavBar;


