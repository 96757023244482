import React, {useState} from 'react'
import styled from "styled-components"
import { formatDate } from '../../utils/formatDate'
import { Link } from "react-router-dom"
import {MicroEntryText} from "./MicroEntryText"
import {Tag} from "../../components/ui/FilterButton"
import {PlayButton} from "../../components/ui/PlayButton"
import {getAbsolutePath} from "../../utils/getAbsolutePath"
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

const Entry  = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  
  & a {
    color: #222;
    text-decoration: none;
  }
`

const Tags = styled.div`
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
  margin-top: 5px;
`

/* const Tag = styled.div`
  font-size: 11px;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: pink;
` */

/* const ImageCap = styled(Link)`
  aspect-ratio: 328 / 267;
  position: relative;
` */
const ImageCap = styled.div`
  aspect-ratio: 328 / 267;
  position: relative;
`

const PlayBtnCap = styled.div`
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

`
/* 
const Title = styled.h2`
  font-family: soleil, sans-serif;
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  
  text-transform: uppercase;
  letter-spacing: .03em;
  transition: color 150ms ease-out;
  color: ${({ isHovered }) => (isHovered ? '#D22730' : '#222')};
  &:hover {
    color: #D22730;
  }
` */
/* const Title = styled(Link)`
  font-family: soleil, sans-serif;
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  color: #222;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .03em;
  transition: color 150ms ease-out;
  color: ${({ isHovered }) => (isHovered ? '#D22730' : '#222')};
  &:hover {
    color: #D22730;
  }
` */

const Date = styled.p`
  margin: 0;
  color: #aaa;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: .03em;
  margin: -8px 0;
  @media (min-width: 600px) {
    font-size: 10px;
    margin: -5px 0;
  }
`

const ModalTrigger = styled.button`
  background: none;
  border: none;
  color: #D22730;
  padding: 0;
  display: inline-block;
  text-transform: uppercase;
  font-style: italic;
  font-size: 10px;
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 150ms  ease-out;
  align-self: flex-start;
  text-align: left;
  &:hover {
    opacity: .6;
  }

`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${props => props.position ? `object-position: ${props.position} ` :  "object-position: top"};
`

function MicroEntry({handleModalTrigger, entry, qString, privatePath}) {



  const history = useHistory();
  //const [isHovered, setIsHovered] = useState(false);

  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
})

  const triggerModal = (txt) => handleModalTrigger(txt);
  const handleTagClick= (t) => history.push(`?tags=${encodeURIComponent(t)}`);

  const isExternal = entry.fields.template === "external link";

  const absolutePath = entry.fields.url && entry.fields.template === "internal link" ? getAbsolutePath(entry.fields.url) : `/custom/${privatePath}/${entry.fields.slug}`;
  
  return (
    <Entry>
      <LinkToggle isExternalLink={isExternal} path={isExternal ? entry.fields.url : absolutePath} qString={qString}>
        <ImageCap /* to={{pathname: absolutePath, state: { qString: qString }}} */
          /* onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} */
        >
          {/* <img src={entry.fields.image.fields.file.url+"?w=328&h=267&f=face&fit=fill"} alt={entry.fields.title}/>  */}
          <Image src={entry.fields.image.fields.file.url+"?w=328"} alt={entry.fields.title} position={entry.fields.thumbnailPosition ? entry.fields.thumbnailPosition : "top"}/>
          {entry.fields.template === `video` ? <PlayBtnCap>
            <PlayButton/>
          </PlayBtnCap> : null }
        </ImageCap>
        </LinkToggle>
        <Date>{formatDate(entry.fields.date, "MMMM d, yyyy")}</Date>
        <div>
         <LinkToggle isExternalLink={isExternal} path={isExternal ? entry.fields.url : absolutePath} qString={qString}>

            <MicroEntryText text={entry.fields.teaserText}/>
          </LinkToggle>
        </div>
        {entry.fields.modalTrigger ? <ModalTrigger onClick={()=>triggerModal(entry.fields.title)}>{entry.fields.modalTrigger}</ModalTrigger> : null}
        <Tags>
          {entry.fields.tags.sort().map(tag=><Tag disabled={!isDesktop} key={entry.sys.id+tag}  onClick={()=>handleTagClick(tag)}>{tag}</Tag>)}
        </Tags>
    </Entry>
  )
}

export default MicroEntry


const LinkToggle = ({isExternalLink, path, qString, children }) => {
  if(!isExternalLink) {
    return  <Link to={{pathname: path, state: { qString: qString }}}>
      {children}
    </Link>
  }
  return <a href={path} target="_blank" rel="noreferrer" >

    {children}
  </a>

}