import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "../react-auth0-spa";
import styled from 'styled-components';
import ContactFoS from './contact/ContactFoS'
import ContactFooter from './contact/ContactFooter'
import ClientList from './ClientList'
//import xxIGFeedGraph from './IGFeedGraphxxx'
//import xxIGFeedGen from './IGFeedGenxxx'
import iconIG from '../assets/iconIG.png'
import iconLI from '../assets/iconLI.png'
import useLockBodyScroll from '../hooks/useLockBodyScroll';
import FadeIn from "react-fade-in";

const Footer = styled.nav` 
  
    background: #fff;
    width: 100%;
    font-family: gopher, sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1.33em;
    letter-spacing: .05em;
    font-size: 14px;
    @media (min-width: 1024px) {
        font-size: 20px;
    }    
`;

const FooterBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
    padding: 15px;
    flex-wrap: wrap;
    & > span {
        font-size: 10px;
    }
    @media (min-width: 1024px) {
        padding: 0 15px;
        align-items: center;
    }    
    @media (min-width: 1024px) {
        height: 50px;
        padding: 0
        flex-wrap: nowrap;
    }  
`;

const FooterBottomLinks = styled.div`
    
    @media (min-width: 1024px) {
        display: flex;
        flex-wrap: nowrap;
        width: auto;
        justify-content: space-between;
    }  
`

/* const FooterTop = styled.div`
    display: flex;
    flex-direction: column
    justify-content: center;
    margin: 0 auto;
    max-width: 1558px;
    width: 100%;
    flex-wrap: wrap;
    padding: 80px 15px 0;
    @media (min-width: 1024px) {
        padding: 80px 5% 0;
    }
    @media (min-width: 1550px) {
        padding: 80px 15px 0;
    }

`; */

const SocialIcons = styled.div`
@media (min-width: 1024px) {
    display: flex;
    column-gap: 30px;
}
`

const SocialIconsPPW = styled.div`

    display: flex;
    column-gap: 8px;

`

const SocialIconPPW = styled.a`
    height: 26px;
`

const SocialIcon = styled.img`
    width: 18px;
    height: 18px;
    @media (min-width: 1024px) {
        margin: 0;
        width: 26px;
        height: 26px;
    }
`;

/* const FooterJoin = styled.div`   
    border-bottom: 1px solid #222;
    width: 100%;
    margin: 0 auto 2em;
    max-width: 1400px;
    line-height: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > a {
        color: #222;
        text-decoration: none;
    }
`; */

/* const Carousel = styled.div`
    height: auto;
    width: 100%;
    @media (min-width: 1024px) {
        height: 280px;
    }    
`; */

const FooterHr = styled.hr`
    border-top: 1px solid #ccc;
    border-bottom: 0;
    margin:0;
`

const FooterLink = styled(Link)`
    font-size: 8px;
    letter-spacing: .78px;
    text-transform: uppercase;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9) ;
    text-decoration: none;
    padding: 0 4em 0 0;
    display: block;
    @media (min-width: 768px) {
        display: inline;
    }    
    @media (min-width: 1024px) {
        font-size: 11px;
    }    
`;

const FooterAnchor = styled.a`
    font-size: 8px;
    letter-spacing: .78px;
    text-transform: uppercase;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9) ;
    text-decoration: none;
    padding: 0 4em 0 0;
    display: block;
    @media (min-width: 768px) {
        display: inline;
    }    
    @media (min-width: 1024px) {
        font-size: 11px;
    }    
`;



const FooterPublic = styled.div`
    width: 100%;
    
    display: flex;
    justify-content: center;
    margin-top: 2em;
    @media (min-width: 1024px) {
        margin-top:0;
    }    
`

const FooterPublicInfo = styled.div`
    width:100%;
    max-width: 1528px;
    display:grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr;
    
    @media (min-width: 1024px) {
        min-height: 156px;
        display:grid;
        grid-template-columns: 25% auto 25%;
        grid-template-rows: 1fr;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
`
const FooterPublicAddress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    border-right: 1px solid #ccc;
    font-size: 12px;
    @media (min-width: 1024px) {
        padding: 0;
        justify-content: center;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        border-right: none;
        font-size: 1em;
    }    
    
`

const FooterPublicContact = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 0;
    font-size: 12px;
    & > div {
        height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        @media (min-width: 1024px) {
            justify-content: flex-end;
        }    
    }
    & > div:first-child{
            border-bottom: 1px solid #ccc;
        }
    @media (min-width: 1024px) {
        padding: 0;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
        font-size: 1em;
        & > div {
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
    }  
    
    & > div a {
        color: #222;
        text-decoration: none;
    }
`

const FooterCopyright = styled.p`
    font-family: soleil, sans-serif;
    font-weight: 100;
    font-style: normal;
    margin: 2em auto;
    line-height: 1.27em;
    letter-spacing: .04em;
    font-size: 7px;
    text-align: left;
    max-width: 1400px;
    padding-left: 1em;
    @media (min-width: 1024px) {
        margin: 2em auto 0;
        padding-bottom: 2em;
        font-size: 10px;
        padding-left: 0;
    }    
`



const Navbar = () => {

    const { user, isAuthenticated, logout } = useAuth0();
    const [clientsVisible, setClientsVisible] = useState(false)

    return (
        <Footer>

            <FooterHr />
            {/* <FooterTop>
            <FooterJoin>
                {isAuthenticated &&
                    <span>Join The Movement</span>
                }
                <a href="https://www.instagram.com/tobereport/" target="_blank" rel="noopener noreferrer">@tobereport</a>
                
                {!isAuthenticated && 
                <div style={{display: "flex", flexWrap: "nowrap", }}>
                    <SocialIcon src={iconIG} alt="Instagram"/>
                    <SocialIcon src={iconLI} alt="LinkedIn"/>
                </div>
                }

            </FooterJoin>
            <Carousel>
                <IGFeedGen____XXXXX/>
            </Carousel>    

        </FooterTop> */}
            {isAuthenticated ?
                <ContactFooter />
                :
                <FooterPublic>
                    <FooterPublicInfo>
                        <FooterPublicAddress>
                            463 Seventh Avenue<br />New York, NY 10018
                        </FooterPublicAddress>

                        <ContactFoS />
                        <FooterPublicContact>
                            <div>212.560.3765</div>
                            <SocialIcons>
                                <a href="https://www.instagram.com/doneger.tobe/" target="_blank" rel="noreferrer"><SocialIcon src={iconIG} alt="Instagram" /></a>
                                <a href="https://www.linkedin.com/company/donegertobe/" target="_blank" rel="noreferrer"><SocialIcon src={iconLI} alt="LinkedIn" /></a>
                            </SocialIcons>
                        </FooterPublicContact>
                    </FooterPublicInfo>
                </FooterPublic>
            }
            <FooterHr />

            {(isAuthenticated && user) &&
                <>
                    <FooterBottom>
                        <FooterBottomLinks>
                            <FooterLink to="/contact">Contact</FooterLink>
                            <FooterLink to="/terms">Terms + Policy</FooterLink>
                            {(user[`https://tobereport/`].company === 'Tobe' || user[`https://tobereport/`].company === 'The Doneger Group') && <FooterAnchor target="_blank" href="https://list.donegertobe.com">Master List</FooterAnchor>}
                            {(user[`https://tobereport/`].company === 'Tobe' || user[`https://tobereport/`].company === 'The Doneger Group') && <FooterAnchor onClick={() => setClientsVisible(!clientsVisible)}>Custom</FooterAnchor>}



                            {/*     <FooterLink to="/creative-direction">CD</FooterLink>
                            <FooterLink to="/business-strategy">BS</FooterLink> */}
                            <FooterLink onClick={() => logout()} to="/logout">Log Out</FooterLink>
                        </FooterBottomLinks>
                        <SocialIconsPPW>

                            <SocialIconPPW href="https://www.linkedin.com/company/donegertobe/" target="_blank" rel="noreferrer"><SocialIcon src={iconLI} alt="LinkedIn" /></SocialIconPPW>
                            <SocialIconPPW href="https://www.instagram.com/doneger.tobe/" target="_blank" rel="noreferrer"><SocialIcon src={iconIG} alt="Instagram" /></SocialIconPPW>
                        </SocialIconsPPW>
                    </FooterBottom>
                    <FooterHr />
                </>
            }


            <FooterCopyright> © 2024 Henry Doneger Associates, Inc.</FooterCopyright>

            {/*         {(clientsVisible && (user[`https://tobereport/`].company === 'Tobe' || user[`https://tobereport/`].company === 'The Doneger Group')) && 
            <FadeIn><ClientList setClientsVisible={setClientsVisible} useLockBodyScroll={useLockBodyScroll}/></FadeIn>
        } */}

            {(clientsVisible) &&
                <FadeIn><ClientList setClientsVisible={setClientsVisible} useLockBodyScroll={useLockBodyScroll} /></FadeIn>
            }
        </Footer>
    );
}

export default Navbar;
