import React from "react";
import styled from "styled-components";
import { formatDate } from "../../utils/formatDate";

const Item = styled.div`
    color: #222;
    transition: color 250ms;
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    & a {
        color: #222;
    }
    @media (min-width: 1024px) {
        padding: 0.75em 3em;
        border-bottom: none;
        color: ${(props) => (props.active ? "#D8292F" : "#222")};
        & a {
            transition: color 250ms;
            color: ${(props) => (props.active ? "#D8292F" : "#222")};
        }
    }
`;

const ItemTitle = styled.h2`
    width: 100%;
    margin: 0;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1.33em;

    letter-spacing: 0.06em;
    font-size: 13px;
    @media (min-width: 1024px) {
        font-size: 18px;
    }
    & > a {
        text-decoration: underline;
     
    }
`;

const ItemInfo = styled.div`
    width: 100%;
    margin: 0;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1.8em;
    letter-spacing: 0.08em;
    font-size: 10px;
    text-transform: uppercase;
    @media (min-width: 1024px) {
        font-size: 14px;
    }
`;

const NewsItem = ({
    item,
    itemIndex,
    currentIndex,
    gotoIndex,
    updateQuote,
    setIsRunning,
}) => {
    const hoverIn = () => {
        setIsRunning(false);
        gotoIndex(itemIndex);
    };

    const hoverOut = () => {
        setIsRunning(true);
    };

    return (
        <Item
            onMouseEnter={hoverIn}
            onMouseOut={hoverOut}
            active={itemIndex === currentIndex ? true : false}
        >
            <ItemTitle>
                {item.fields.url ? (
                    <a
                        href={item.fields.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.fields.title}
                    </a>
                ) : (
                    item.fields.title
                )}
            </ItemTitle>
            <ItemInfo>
                {formatDate(item.fields.date, "MMMM yyyy")} | {item.fields.url2}
            </ItemInfo>
        </Item>
    );
};

export default NewsItem;
