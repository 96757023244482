import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components'


const PressText = styled.p`
color: #d8292f;
line-height: 1.22em;
letter-spacing: 0.04em;
font-family: freight-big-pro, serif;
font-weight: 300;
font-style: normal;
text-align: center;
font-size: 40px;
margin: 0;
padding: 0 1em;
`;


const options = {
  renderMark: {
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <PressText>{children}</PressText>,
  },
};




export const PressQuote = ({ text }) => {
  return documentToReactComponents(text, options);
}