import React, { useState } from "react"
//import { useAuth0 } from "../react-auth0-spa"

import NotSubscribedMsg from '../components/NotSubscribedMsg'


const NotSubscribed = ({ path }) => {

  document.title = `Not Subscribed`
  const [open, setOpen] = useState(false);

  const subscribePage = {
    "great-ideas": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Great Ideas`} msg={`It’s called Great Ideas for a reason. Inspiration comes from keen observation and critical thinking.`} msg2={<p>For a never-ending stream of it, <span onClick={setOpen}>inquire</span>.</p>} />,
    "consumer-insights": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Consumer Insights`} msg={`This intel helps you better understand and connect with the audience you’re wooing.`} msg2={<p>To further explore, <span onClick={setOpen}>drop us a note</span>.</p>} />,
    "business-strategy": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Business Strategy`} msg={`This is the place to be for leaders and strategists looking at the big picture and planning short-, mid-, and long-term initiatives.`} msg2={<p><span onClick={setOpen}>Click</span> to learn more.</p>} />,
    "creative-direction": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Creative Direction`} msg={`Design direction is trickier these days, but we’ve worked out a new model. Our approach and application are worth a look.`} msg2={<p><span onClick={setOpen}>Set up</span> a time to speak.</p>} />,
    "merchandising": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Merchandising`} msg={`A transfer of power from brand to consumer is well underway. Curating and assorting is the key to modern merchandising.`} msg2={<p><span onClick={setOpen}>Connect</span> with our experts.</p>} />,
    "design-movements": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Design Movements`} msg={`D|T’s modernization of the trend forecasting and design disciplines, addressing wardrobing and styling from a consumer-centric POV.`} msg2={<p><span onClick={setOpen}>Connect</span> with our experts.</p>} />,
    "color-and-print": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Color and Print`} msg={`Zooming in on color and print to support merchandising and design with assessment of the key elements and newness-drivers.`} msg2={<p><span onClick={setOpen}>Connect</span> with our experts.</p>} />,
    "runway-and-research": <NotSubscribedMsg open={open} setOpen={setOpen} section={`Runway and Research`} msg={`Discovery and inspiration researched, assessed and skillfully curated for share out, to save you valuable time and money.`} msg2={<p><span onClick={setOpen}>Connect</span> with our experts.</p>} />,
    "business-insights": <NotSubscribedMsg open={open} setOpen={setOpen} section={`business-insights`} msg={`The subjects and issues that require context, perspective, and thorough discussion for smart commercial application.`} msg2={<p><span onClick={setOpen}>Connect</span> with our experts.</p>} />,
  }
  const renderSubscribePage = () => {
    const paths = path.split("/")
    return subscribePage[paths[1]]
  }




  return (
    <>

      {renderSubscribePage()}

    </>
  );
};

export default NotSubscribed;
