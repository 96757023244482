import React, { useEffect, useRef, useState} from 'react'
import Error404 from "../../views/Error404"
import { useQueryParams } from 'use-query-params'
import usePrivateValidate from '../../hooks/usePrivateValidate'
import { useHistory } from "react-router-dom";




export const MicroManager = (props) => {

  const history = useHistory();
    console.log(`from micromanager`, props.match.params.privatePath)
console.log(props.clientInfo)
    const invalidRoutesRef = useRef([])
    const invalidRouteRef = useRef()
    //const params = useQueryParams();
    const [isLoading, setIsLoading] = useState(true);

    const {isValidated, loading} = usePrivateValidate(props.match.params.privatePath)

/*     const fetchToValidateRoute = async () => {

      invalidRoutesRef.current.push("/for-walmart")
      setIsLoading(false);
      if(props.match.params.privatePath === "for-walmart"){
        return true
      } else {
        invalidRouteRef.current = true
      }
    };
 */

    useEffect(() => {

    }, [isValidated]);

    useEffect(() => {
      //fetchToValidateRoute();
    }, []);
  
 if (isLoading || isValidated === null || loading) {
      //return <></>;
    }  

/*     if (!isValidated ) {
      return <></>;
    }  */

  if (invalidRouteRef.current === true  || (isValidated === false) ) {
        console.log(`isVAkidated === false`)
        return <Error404 />;
    }  

/*     if(isValidated === true){
      return props.children;
  }   */

  return props.children;
    
  }