import React  from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
//import logo from '../assets/logo-tdg.svg'
//import logo from '../assets/logo-tobetdg.svg'
import logo from '../assets/structure/logo-donegertobe.svg'
import loginCircle from '../assets/fos-fullcircle3.svg'

const Nav = styled.nav` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  height: 56px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background: #f8f8f8;
  left: 0;
    right: 0;
`;  

const NavContainer = styled.nav` 
  display: flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLinksContainer = styled.nav` 
  justify-content: space-between;
  
  flex-flow: row wrap;
  list-style: none;
  width: auto;

  margin: 0;
  padding: 0;
  display:flex;
  @media (min-width: 768px) {
    display:flex;
    width: 100%;
    max-width: 190px;
  }

`;

const NavItem = styled(NavLink)`
  font-size: 11px;
  letter-spacing: .107em;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  text-decoration: none;
  height: 56px;
  align-items: center;

  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  -webkit-transition: all .25s;
	-moz-transition: all .25s;
  transition: all .25s;
  display: flex;
  opacity: ${props => props.intro ? 0 : 1};
  @media (min-width: 768px) {
    
    font-size: 14px;
  }
  &.navbar__link--active {
    color: #d8292F;
  }
  &:hover {
    color: #d8292F;
  }
  &:last-child{
    border-right:none;
  }
  &:first-child{
  
  }


  & > img {
    position: absolute;
    width: 100%;
    max-width: 120px;
  }  
  @media (max-width: 768px) {
    width: 136px;
    margin-right: 15px;
  }

`;


const NavLogo = styled(NavLink)`
  flex: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 769px) {
  
  }
`;


const Logo = styled.img`
  width: 140px;
  margin: 0 15px;
  padding-bottom: 4px;
  @media (min-width: 769px) {
    margin: 0 25px;
  }
`


const PrePayNavBar = (props, {intro, slug=`/`}) => {

  //const { match, location, history } = props;
  const { location } = props;

  const homeLink = location.pathname.startsWith(`/projects/`) ? location.pathname.split('/').slice(0,3).join('/') + '/' : `/`



  return (
    <>
    <Nav>
      <NavContainer>
        <NavLogo smooth={`true`} to={homeLink}>
          <Logo src={logo} alt="DONEGER | TOBE" width="300" height="59"/>
        </NavLogo>
        <NavLinksContainer>
            
            <NavItem activeClassName="navbar__link--active" to={`${homeLink !== `/` ? `${homeLink}contact` : `/contact`}`}>
              <span>Contact</span>
              <img alt="log in" src={loginCircle}/>
            </NavItem>
        </NavLinksContainer>
      </NavContainer>
    </Nav>

    </>
  );
}

export default withRouter(PrePayNavBar);

