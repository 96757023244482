import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuth0 } from "../react-auth0-spa";
import { config } from '../utils/constants'
import { useHistory } from "react-router-dom";
import { delay } from '../utils/delay';


export default function usePrivateQuery(queryObj) {




  const [entriesLoading, setEntriesLoading] = useState(true)
  const [entries, setEntries] = useState();
  const [microTags, setMicroTags] = useState([])
  const [displayName, setDisplayName] = useState()
  const { getTokenSilently } = useAuth0()

  const history = useHistory();



  useEffect(() => {

    const fetchData = async () => {
      const token = await getTokenSilently()
      setEntriesLoading(true)


    try{

      const response = await axios.get(`${config.API_URL}/clientMicro/getList`, {
        params: queryObj,
        headers: { Authorization: `Bearer ${token}` }
      })


      setEntries({ total: response.data.total, currentItems: response.data.items.length, items: [...response.data.items], })
      setMicroTags(response.data.tags)
      setDisplayName(response.data.displayName)
      await delay(500)
      setEntriesLoading(false)

    }catch(e){
      //history.push("/home");
      setEntriesLoading(false)
      //history.push("/home");
      //window.location.replace("/")

      const errorStatus = e.response.status;
      console.log(errorStatus)
      if(errorStatus === 403){
        //setLoading(false)
        //setIsValidated(false)
        //history.push("/home")
        window.location.replace("/")
      //alert(`FORBIDDEN`)
      } else {
        
        window.location.replace("/")
      }


    }
   

    }
    fetchData()

  }, [queryObj])

  return { entries, entriesLoading, microTags, displayName }
}
