import React from 'react'
import styled from 'styled-components'
import SaveShare from '../../components/SaveShare'

import { formatDate } from '../../utils/formatDate'


const Entry = styled.div`
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: auto auto auto; 
    position: relative;
    padding: 0;
    & iframe {
        width: 100%;
        height: 25vh;
        @media (min-width: 768px) {
            height: 70vh;
        }
    }
    @media (min-width: 768px) {
        padding: 0 40px;
    }    
`;

const EntryTitle = styled.h1`
    font-size: 40px;
    text-decoration: none;
    color: rgba(0,0,0,.9);  
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1em;
    letter-spacing: .05em;
    margin: .75em 0 0em;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    @media (min-width: 768px) {
        font-size: 80px;
    }   
`;

const EntryMeta = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    justify-content: space-between;
    margin: 1em 0 3em;
    & > span {
        text-transform: uppercase;
    }    
`;

const SaveShareInfo = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 20px 0 0;
    display: flex;
    justify-content: flex-end;
    & > span > * {
        border-right: 1px solid rgba(0, 0, 0, 0.9);
    }
    & > span > *:last-child {
        border-right: none;
        padding-right: 0!important;
    }
`;

const ButtonDownload = styled.a`
    display: inline-block;
    transition: all 250ms;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-family: soleil;
    cursor: pointer;
    font-size: 12px;
    padding: 0 1em;
    line-height: 1em;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
        color: rgba(0, 0, 0, 0.3);
    }
`


function Embed({entry}) {
  return (
 
    <>
        {entry.fields.url && entry.fields.url.endsWith(".pdf") && entry.fields.template === 'indesign' && <SaveShareInfo>
            <ButtonDownload href={entry.fields.url.trim()} target="_blank">download</ButtonDownload>
        </SaveShareInfo>
        }

          <Entry>
              <EntryTitle>{entry.fields.title}</EntryTitle>
              <EntryMeta>
                  <span>{formatDate(entry.fields.date, 'MMMM yyyy')}</span>
              </EntryMeta>
    
                {entry.fields.template === 'indesign' && <div dangerouslySetInnerHTML={{ __html: entry.fields.embed }} />}
                {entry.fields.template === 'video' && <div>
                    <video width="1600" height="900" autoplay controls style={{width: "100%", height: "auto"}}>
                        <source src={entry.fields.url} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>}

        </Entry>

    </>
  )
}

export default Embed