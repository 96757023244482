import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios'
import arrowRight from '../../assets/arrow-right-red.svg'
import checkRed from '../../assets/redcheck.svg'
import { validateEmail } from '../../utils/validateEmail'
import { config } from '../../utils/constants'


const FooterEmailInput = styled.input`
    border:none;
    display: flex;
    flex-grow: 1;
    height: 40px;
    letter-spacing: .06em;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 17px;
`
const FooterPaywallEmailForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & > p {
        margin: 0 .5em 0 0;
    }
    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
    }
`

const FooterPaywallEmail = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 1em;
    @media (min-width: 768px) {
        margin: 15px auto;
        justify-content: center;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        padding: 1em 5%;
    }  
    @media (min-width: 1550px) {
        padding: 1em 15px;
    }    


`

const FooterButton = styled.button`
    border: none;
    padding: 0;
    width: 8px;
    background: #fff;
    cursor: ${props => ((!props.subscribed && !props.disabled) ? `pointer` : `not-allowed`)};
    transition: opacity 250ms ease-out;
    opacity: ${props => (!props.disabled ? 1 : .5)};
`
const FooterForm = styled.form`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    border-bottom: 1px solid #ccc;
    @media (min-width: 1024px) {
        width: auto;
    }    
`

const ContactFooter = () => {

    const [email, setEmail] = useState("")
    const [message, setMessage] = useState()
    const [emailLoading, setEmailLoading] = useState(true)
    const [subscribed, setSubscribed] = useState(false)



    const subscribe = useCallback((e) => {
        e.preventDefault()
        setSubscribed(true)
        axios.post(`${config.API_URL}/request/contact`, {
            email: email,
            captureType: `member`,
            originSite: `DONEGER|TOBE`,
            originSource: `PPW`,
            originDetail: `Footer - Schedule`
        }).then(function (response) {
            setMessage(`Thank you. We'll meet you in your inbox.`)
        })
    }, [email])

    /*     const subscribe = useCallback((e) => {
            e.preventDefault()
            setSubscribed(true)
            axios.post(`${config.API_URL}/request/contact`, {    
                email: email,
                captureType: `member`,
                origin: `Footer - Schedule`
            }).then(function (response) {
                setMessage(`Thank you. We'll meet you in your inbox.`)
            })
        }, [email]) */

    useEffect(() => {
        const validEmail = validateEmail(email);
        setEmailLoading(!validEmail)
    }, [email])


    return (
        <FooterPaywallEmail>

            <FooterPaywallEmailForm>


                <p>Interested in more? <u>Schedule</u> a presentation to pick our brain.</p>
                {message ? <p style={{ margin: 0 }}>{message}</p>
                    :
                    <>
                        <FooterForm onSubmit={subscribe}>

                            <FooterEmailInput disabled={subscribed} required type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                            <FooterButton subscribed={subscribed} disabled={emailLoading}>{!subscribed ? <img alt="contact" src={arrowRight} /> : <img alt="contacted" src={checkRed} />}</FooterButton>

                        </FooterForm>
                    </>
                }

            </FooterPaywallEmailForm>

        </FooterPaywallEmail>

    );
};

export default ContactFooter;

