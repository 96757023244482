import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Circle = styled(NavLink)` 
 
    
    background-color: #D8292F;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.22);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.22);
    -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.22);
    
    scale: ${props => (props.rendered ? 1 : 0)};
    
    position: fixed;
    bottom: 20px;
    
    cursor:pointer;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;

    transition: all 250ms ease-out;
    line-height: 1.125em;
    font-weight: 300;
    width: ${props => (props.open ? `calc(100% - 80px)` : `85px`)};
    height: ${props => (props.open ? `75px` : `85px`)};
    border-radius: ${props => (props.open ? `37.5px` : `42.5px`)};
    right: ${props => (props.open ? `40px` : `30px`)};
    display: none;
    @media (min-width: 768px) {
        display: flex;
        width: 105px;
        height: 105px;
        font-size: 16px;
        border-radius: 50%;
        bottom: 50px;
        right: 50px;
        &:hover {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
    }
`;


const CustomButton = ({ open, setOpen }) => {

    const [rendered, setRendered] = useState(false)
    const location = useLocation()

    useEffect(() => {

        setTimeout(() => {
            location.pathname === `/custom-work` ? setRendered(false) : setRendered(true)
        }, 1000)
    }, [location])


    const triggerLink = () => {
        if (open) {
            setOpen(false)
        }
    }

    return (

        <Circle
            activeClassName="custom__link--active"
            className="custom__link"
            to="/custom-work"
            open={open}
            onClick={triggerLink}
            rendered={rendered}
        >
            Your Custom Work

        </Circle>
    );
};

export default CustomButton;