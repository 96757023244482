import styled from 'styled-components'

const FilterButton = styled.button`
    font-family: soleil, sans-serif;
    border-radius:9999px;
    transition: all 150ms ease-out;
    background: ${props => props.active ? "#D8292F" : "#FFF"};
    cursor: pointer;
    height: 30px;
    color: ${props => props.active ? "#FFF" : "#D8292F"};
    border: 1px solid #D8292F;
    text-transform: uppercase;
    padding: 0 1em;
    font-size: 10px;
    letter-spacing: .06em;
    line-height: 1;
    &:hover {
        background: #D8292F;
        color: #FFF;
    }
    @media (min-width: 1024px) {
        height: 36px;
        font-size: 14px;
    }
`

export const FtrCustomBtn = styled(FilterButton)`
    font-weight: 300;
    white-space: nowrap;
        @media (min-width: 600px) {
       font-size: 11px;
}
    @media (min-width: 1024px) {
        height: 34px;
        font-size: 12px;
    }
`

export const FtrCustomCatBtn = styled(FtrCustomBtn)`
    border-radius: 0;
    font-weight: 500;
    
`

export const Tag = styled(FilterButton)`
    border-color: #aaa;
    background: #FFF;
    height: 23px;
    font-size:8px;
    color: #aaa;

    &:hover {
        background: #D8292F;
        border-color: #D8292F;
        color: #FFF;
        &:disabled {
            border-color: #aaa!important;
            background: #FFF!important;
            color: #aaa!important;
            cursor: unset;
        }
    }

    @media (min-width: 1024px) {
        height: 28px;
        font-size: 10px;
        color: #aaa;
    }
`

export default FilterButton