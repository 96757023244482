//fetch standard article
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import {config} from '../utils/constants'
import { useAuth0 } from "../react-auth0-spa";

export default function usePrivateEntryQuery(pathName, slug) {

  const [entry, setEntry] = useState();
  const [loading, setLoading] = useState(true)
  const history = useHistory();
  const { getTokenSilently } = useAuth0()

  useEffect(() => {

    setLoading(true)

    const fetchData = async() => {
      const token = await getTokenSilently()
      setLoading(true)

      if(token){
        await axios.get(`${config.API_URL}/clientMicro/getEntry`, {
          params: {
            pathName,
            slug
          },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(function (response) {
            document.title = response.data.fields.title;
            setEntry(response.data);
            window.scrollTo(0, 0) 
            setLoading(false)
        })
         .catch(function (error) {
            history.push("/");
        }); 
      }

    }
    fetchData()
  }, [])

  return { loading, entry  }
}
