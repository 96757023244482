import React, { useState, useEffect, } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'
import { useAuth0 } from "../react-auth0-spa";

import {FtrCustomBtn, FtrCustomCatBtn} from '../components/ui/FilterButton';
import PaginationCap from '../components/ui/PaginationCap'
import MicroEntry from '../components/microsite/MicroEntry';

import ContactModal from '../components/contact/ContactModal'
import usePrivateQuery from '../hooks/usePrivateQuery';
import useDebounce from '../hooks/useDebounce';

import styled from 'styled-components';


import ReactPaginate from 'react-paginate';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import FadeIn from "react-fade-in"

const Home = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin: 0 auto;
    padding: 0 20px;
    @media (min-width: 600px) {
        padding: 0;
        row-gap: 35px;
    }  
    @media (min-width: 1280px) {
        row-gap: 40px;
        margin: 0 calc(-10% - 30px);
    }  

`

const FilterButtonsCap = styled.div`
    @media (min-width: 600px) {
        ___padding: 40px 0;

    }
`
const FilterButtonsLines= styled.div`
    @media (min-width: 600px) {
        padding: 40px 15px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
    }
     @media (min-width: 1024px) {
        padding: 40px 20%;
        width: 140%;
        margin-left: -20%;
        margin-right: -20%;
    }
`

const FilterButtons = styled.div`
    display: none;
    @media (min-width: 600px) {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;
        max-width: 1064px;
        margin: 0 auto;
    }
`

/* const FilterButtonsMobile = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 15px 0;
    
    @media (min-width: 768px) {
        display: none;
    } 
`

const FilterButtonMobile = styled.button`
    padding: 0; 
    border: none;
    cursor: pointer;
    color: ${props => props.active ? "#D8292F" : "#414042"};
    text-transform: uppercase;
    font-size: 9px;
    font-family: soleil, sans-serif;
    transition: all 150ms ease-out;
    &:hover {
        color: #D8292F;
    }
` */

const FilterButtonsMobile = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 9px;

    width: 100%;
    margin: 10px 0;
    @media (min-width: 600px) {
        display: none;
    } 
`

const FilterButtonMobile = styled.button`
    height: 28px;
    border: 1px solid #D8292F;
    padding: 0; 
    cursor: pointer;
    color: ${props => props.active ? "#FFF" : "#D8292F"};
    background: ${props => props.active ? "#D8292F" : "#FFF"};
    text-transform: uppercase;
    font-size: 13px;
    font-family: soleil, sans-serif;
    transition: all 150ms ease-out;
    font-weight: 100;

`



const MasonryCap = styled.div`
    width: 100%;
`

const Entries = styled(FadeIn)`
    display: grid;
    grid-template-columns: 1fr;
    gap: 35px;
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
        gap: 50px;
        margin: 20px auto 0;
    }
    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 50px;
    }
`

const MicroTagCategory = styled.div`
    display: flex;
    flex-wrap: nowrap;
    column-gap: 20px;
`

const MicroCategoryTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
`

const Display = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    @media (min-width: 600px) {
        row-gap: 4px;
    }
`
const DisplayName = styled.h1`
    line-height: 1;
    ___font-size: 35px;
    font-size: 40px;
    @media (min-width: 600px) {
        font-size: 55px;
    }
    @media (min-width: 1024px) {
        font-size: 60px;
    }
`
const DisplayText = styled.p`
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: italic;
    margin: 0;
    font-size: 21px;
    __font-size: 30px;
    letter-spacing: .02em;   
    @media (max-width: 767px) {
       line-height: 1em;
    }
    @media (min-width: 600px) {
        font-size: 35px;
    }
    @media (min-width: 1024px) {
        font-size: 40px;
    }  
`

const Help = styled.div`
    width: 100%;
    max-width: 1064px;
    margin: -40px auto 85px;
    display: flex;
    align-items: center;
    flex-direction: column;
`

const Look = styled.p`
font-family: freight-big-pro, serif;
    font-weight: 300;
    
    font-size: 30px;
    color: #D22730;
    margin:0;
`

const FindIt = styled.button`
    background: none;
    border: none;
    color: #D22730;
    padding: 0;

    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: italic;
    font-size: 30px;
    cursor: pointer;
    border-bottom: 1px solid #D22730;
`


function allValuesInArray(arr1, arr2) {
    return arr1.every(value => arr2.includes(value));
  }

  function removeValues(arr1, arr2) {
    return arr2.filter(value => !arr1.includes(value));
  }

const limit = 9


const PrivateTab = (props) => {

    const {clientInfo} = useAuth0()
    document.title = clientInfo.clientName;
    const history = useHistory();
    const { search } = useLocation()

    const [page] = useQueryParam('page', NumberParam);
    const [tags] = useQueryParam('tags', StringParam);
    //const [open, setOpen] = useState(false); //mobile menu?
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState();
    //const [modalSubTitle, setModalSubTitle] = useState();

    const defaultObj = {
        page: page ? page : 1,
        //tags: clientInfo?.microTags ? clientInfo.microTags.join(",") : '',
        limit,
        pathName: props.match.params.privatePath
    }

    const [dataObj, setDataObj] = useState(defaultObj)
    //const debouncedSearchTerm = useDebounce(dataObj, 250);
    const debouncedSearchTerm = useDebounce(dataObj, 250);

    
    const { entries, entriesLoading, microTags, displayName } = usePrivateQuery(debouncedSearchTerm)




/*     const handleDropdownClick = () => {
        setOpen(prevState => !prevState)
    } */

    const handlePageClick = (data) => {
        const pg = (data.selected + 1)
        const searchParms = new URLSearchParams(search)
        searchParms.set("page", pg)
        history.push('?' + searchParms.toString())
    }

/*     const handleTagClick = (microTag) => {
        const noTag = microTag === tags ? true : false
        history.push(noTag ? "?" : `?tags=${encodeURIComponent(microTag)}`)
    }  */

    const handleTagClick = (microTag) => {
        let newTags;
        let existingTags = tags ? tags.split(",") : []
        if (existingTags && existingTags.includes(microTag)) {
            newTags = existingTags.filter(tag => tag !== microTag);
        } else {
            newTags = tags ? [...existingTags, microTag] : [microTag];
        }
        //setTags(newTags);
        const searchParams = new URLSearchParams(search);
        searchParams.set("page", 1)
        searchParams.set("tags", newTags.join(","));
        history.push('?' + searchParams.toString());
    }


    const handleCategoryClick = (microTags) => {
        let newTags;
        let existingTags = tags ? tags.split(",") : []
        const microTagsArr = microTags.split(",")
        if (existingTags && allValuesInArray(microTagsArr, existingTags)) {
            //newTags = existingTags.filter(tag => tag !== microTags);
            newTags = removeValues(microTagsArr, existingTags)
        } else {
            newTags = tags ? [...existingTags, microTags] : [microTags];
        }
        //setTags(newTags);
        const searchParams = new URLSearchParams(search);
        searchParams.set("tags", newTags.join(","));
        history.push('?' + searchParams.toString());
    }
    
    

    const handleModalTrigger = (txt) => {
 
        setModalTitle(txt)
        setModalOpen(true)
    }
    

    useEffect(() => {
        window.scrollTo(0, 0)
        let queryObj = {}
        queryObj = page ? { ...queryObj, page: page} : queryObj
        queryObj = tags ? { ...queryObj, tags: tags } : queryObj
        queryObj = { ...queryObj, pathName: props.match.params.privatePath }
        setDataObj(queryObj)
    }, [page, tags])



    return (
        <Home>

            <Display>
                <DisplayName>D|T for {displayName}</DisplayName>
                <DisplayText>Selected and created specifically for {displayName}.</DisplayText>
         
            </Display> 

<FilterButtonsLines>
            <FilterButtonsCap>
{/*                 <FilterButtonsMobile>
                {
                    microTags.map((microTagCategory)=><FilterButtonMobile key={microTagCategory+"m"} active={tags && tags === microTagCategory.values.join(",")}  onClick={() => handleTagClick(`${microTagCategory.values.join(",")}`)}>{microTagCategory.key}</FilterButtonMobile>)
                }
                </FilterButtonsMobile> */}
                <FilterButtonsMobile>
                {
                    microTags.map((microTagCategory)=><FilterButtonMobile key={microTagCategory.values+"m"} 
                        /* active={tags && tags === microTagCategory.values.join(",")}  
                        onClick={() => handleTagClick(`${microTagCategory.values.join(",")}`)} */
                        active={tags && allValuesInArray(microTagCategory.values, tags.split(","))}
                        onClick={() => handleCategoryClick(`${microTagCategory.values.join(",")}`)}
                    >{microTagCategory.key}</FilterButtonMobile>)
                }
                </FilterButtonsMobile>
                <FilterButtons>
                {
                    microTags.map((microTagCategory, index)=><MicroTagCategory key={microTagCategory+index} >
                        <FtrCustomCatBtn 
                            //active={tags && tags === microTagCategory.values.join(",")}  
                            active={tags && allValuesInArray(microTagCategory.values, tags.split(","))}
                            onClick={() => handleCategoryClick(`${microTagCategory.values.join(",")}`)}
                        >{microTagCategory.key}</FtrCustomCatBtn>
                        <MicroCategoryTags>{microTagCategory.values.map(microTag=><FtrCustomBtn key={microTag+"d"} onClick={() => handleTagClick(microTag)} active={tags && tags.includes(microTag)}>{microTag}</FtrCustomBtn>)}</MicroCategoryTags>
                    </MicroTagCategory>)
                }

                </FilterButtons>
            </FilterButtonsCap>
            </FilterButtonsLines>

            {entriesLoading ?
                <></>
                :
                <>
                <MasonryCap>
                    <Entries>
                        {entries &&
                            entries.items.map((entry, index) =>
                                <MicroEntry handleModalTrigger={handleModalTrigger} entry={entry} key={entry.sys.id} qString={search} /* privatePath={clientInfo.microPath} */ privatePath={props.privatePath}/>
                            )
                        }
                    </Entries>
                    {(!entriesLoading && (entries?.total > limit)) ? 
                        <PaginationCap>
                            <ReactPaginate
                                //key={tag + page}
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'pagination__break'}
                                pageCount={entries.total / limit}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={5}
                                //initialPage={page ? page : 1}
                                forcePage={page ? page - 1 : 0}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={`pagination__page`}
                                activeClassName={'active'}
                            />
                        </PaginationCap>
                        : <div style={{height: "40px"}}></div>
                    }
                </MasonryCap>

                <Help>
                    <Look>Looking for something specific?</Look>
                    <FindIt onClick={()=>handleModalTrigger(`Looking for something specific?`)}>Let us find it for you</FindIt>
                </Help> 
                <CSSTransition
                    in={modalOpen}
                    timeout={300}
                    classNames="contact-modal"
                    unmountOnExit
                >
                    <ContactModal open={modalOpen} setOpen={setModalOpen} captureType={`member`} originSource={`PPW`} originDetail={`Client Micro - ${modalTitle}`} title={modalTitle} successMsg={`We’ll meet you in your inbox to discuss further.`} />
                </CSSTransition>
                </>
            }
        </Home>
    );
};

export default PrivateTab;

/* {JSON.stringify(clientInfo)} */
/*             <FilterButtons>
                <FilterButton active={!tag} onClick={() => history.push("/home")}>all</FilterButton>
                <FilterButton active={tag === "The Weekly"} onClick={() => handleTagClick("The+Weekly")}>The Weekly</FilterButton>
                <FilterButton active={tag === "Featured Stories"} onClick={() => handleTagClick("Featured+Stories")}>Featured Stories</FilterButton>
                <FilterButton active={tag === "Latest Business"} onClick={() => handleTagClick("Latest+Business")}>Latest Business</FilterButton>
                <FilterButton active={tag === "In The Press"} onClick={() => handleTagClick("In+The+Press")}>In The Press</FilterButton>
            </FilterButtons> */




/*             <MobileDropdown open={open} handleDropdownClick={handleDropdownClick} height="138px" display={tag ? tag : "All"}>
                <FilterButtonMobile active={!tag} onClick={() => history.push("/home")}>all</FilterButtonMobile>
                <FilterButtonMobile active={tag === "The Weekly"} onClick={() => handleTagClick("The+Weekly")}>The Weekly</FilterButtonMobile>
                <FilterButtonMobile active={tag === "Featured Stories"} onClick={() => handleTagClick("Featured+Stories")}>Featured Stories</FilterButtonMobile>
                <FilterButtonMobile active={tag === "Latest Business"} onClick={() => handleTagClick("Latest+Business")}>Latest Business</FilterButtonMobile>
                <FilterButtonMobile active={tag === "In The Press"} onClick={() => handleTagClick("In+The+Press")}>In The Press</FilterButtonMobile>
            </MobileDropdown> */

/* export const Pills = ({path}) => {
    //const { search } = useLocation()
    const {loading, isValidated, validClient} = usePrivateValidate(path)
    const history = useHistory();
    const [tags] = useQueryParam('tags', StringParam);

    const handleTagClick = (microTag) => {
        const noTag = microTag === tags ? true : false
        history.push(noTag ? "?" : `?tags=${microTag}`)
        console.log(noTag)
    } 

    const [pills, setPills] = useState([])

    useEffect(()=>{
        (validClient && pills.length === 0) && setPills(validClient.microTags)
    }, [validClient])

    if(pills.length === 0){
        return <></>
    }

    return pills.map(microTag=><FilterButton key={microTag} active={tags && tags.includes(microTag)}  onClick={() => handleTagClick(microTag)}>{microTag}</FilterButton>)
}
 */

/* 
const handleTagClick = (microTag) => {
    let tagsArray =  tags ? [...tags.split(",")] : []
    if(tagsArray.includes(microTag)){
        console.log(`remove tag`)
        const newArr = tagsArray.filter(t => {
            return t !== microTag
        })
        history.push(newArr.length === 0 ? `/private` : `?tags=${newArr.join()}`)
    } else {
        tagsArray = [...tagsArray, microTag]
        history.push(`?tags=${tagsArray.join()}`)
    }
} 

 */