import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios'
import arrowRight from '../../assets/arrow-right-grey.svg'
import checkGrey from '../../assets/greycheck.svg'
import { config } from '../../utils/constants'
import { validateEmail } from '../../utils/validateEmail';

const FooterEmailInput = styled.input`
    border:none;
    display: flex;
    flex-grow: 1;
    height: 40px;
    letter-spacing: .06em;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 12px;
    &:disabled {
        background: none;
    }
    @media (min-width: 1024px) {
        font-size: 17px;
    }    
    
`
const FooterPublicEmailForm = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    padding: 0 0 1em;
    margin-top: -1em;
    & > p {
        margin: 0;
        font-size: 12px;
        @media (min-width: 1024px) {
            font-size: 1em;
        }    
    }
    @media (min-width: 1024px) {
        width: auto;
        padding: 0;
        margin-top: 0;
    }    
`

const FooterPublicEmail = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 1em;
    border-bottom: 1px solid #ccc;
    @media (min-width: 1024px) {
      
        justify-content: center;
        padding: 0 1em;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: none;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        border-bottom: none;
    }  
`

const FooterButton = styled.button`
    border: none;
    padding: 0;
    width: 8px;
    background: #fff;
    cursor: ${props => ((!props.subscribed && !props.disabled) ? `pointer` : `not-allowed`)};
    transition: opacity 250ms ease-out;
    opacity: ${props => (!props.disabled ? 1 : .5)};
`

const FooterForm = styled.form`
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: row;
`

const ContactFoS = () => {

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState();
    const [emailLoading, setEmailLoading] = useState(true);
    const [subscribed, setSubscribed] = useState(false);

    const subscribe = async (e) => {
        e.preventDefault()
        setSubscribed(true)

        /*         axios.post(`${config.API_URL}/request/getEntry`, {    
                    email: email,
                    captureType: "prospect" */
        axios.post(`${config.API_URL}/request/contactBuffered`, {
            //axios.post(`${config.API_URL}/request/contact`, {                
            email: email,
            captureType: "prospect",
            originSite: `DONEGER|TOBE`,
            originSource: `FOS`,
            originDetail: `Prepaywall Footer`,

        }).then(function (response) {
            setMessage(`Thank you. We’ll be in touch.`)
        }).catch(function (error) {
            /* if(error.response.status===501){
                setMessage(`Thanks for your continued interest in DONEGER | TOBE's great ideas and insights. Let's schedule a time to discuss how we can work together regularly.`)
            }  */
            setMessage(`Thank you. We’ll be in touch.`)
        })
    }




    useEffect(() => {
        const validEmail = validateEmail(email);
        setEmailLoading(!validEmail)
    }, [email])



    return (
        <FooterPublicEmail>

            <FooterPublicEmailForm>
                {message ? <p>{message}</p>
                    :
                    <>
                        <p>Interested in learning more? Let’s set a meeting.</p>
                        <FooterForm onSubmit={subscribe}>
                            <FooterEmailInput disabled={subscribed} required type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                            <FooterButton subscribed={subscribed} disabled={emailLoading}>{!subscribed ? <img alt="submit" src={arrowRight} width="8" height="15" /> : <img alt="submitted" src={checkGrey} />}</FooterButton>
                        </FooterForm>
                    </>
                }
            </FooterPublicEmailForm>

        </FooterPublicEmail>

    );
};

export default ContactFoS;


/* 
{
    //axios.post(`${config.API_URL}/request/contact`, {                
    email: email,
    captureType: "prospect",
    origin: "FOS",
    originSource: `FOS`,
    originDetail: `Prepaywall Footer`,
    message: {
        FOS: "Prepaywall Footer"
    } */