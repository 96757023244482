import React from 'react'
import styled from "styled-components"

const PlayBtn = styled.div`
    background: none;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    position: relative; 
    text-indent:-9999px;
    background: rgba(0,0,0,.8);
`

const PlayArrow = styled.div`
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 15px solid #fff;
    border-bottom: 10px solid transparent;
    position:absolute;
    top:30%;
    left:40%;    
`


export function PlayButton() {
  return (
    <PlayBtn>
        <PlayArrow />
    </PlayBtn>
  )
}
