import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import axios from 'axios'
import { config } from '../../utils/constants'

const CustomContact = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  max-width: 1400px;
  margin: 15px;
  position: relative;
  overflow-x: hidden;
  @media (min-width: 768px) {
    margin: 60px auto 140px;
    border: 1px solid #CCC;
  } 
`;

const CustomContactSent = styled.div`
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  font-family: gopher, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  line-height: 2em;
  letter-spacing: .05em;
  font-size: 25px;
`;

const CustomContactText = styled.p`
  padding: 30px;
  margin:0;
  
  font-family: gopher, sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: .05em;
  font-size: 20px;
  @media (min-width: 768px) {
    border-bottom: 1px solid #CCC;
  }  
`
const ButtonCap = styled.div`
  padding: 30px;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 768px) {

  }   
`

const Button = styled.button`
  font-family: soleil, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  background: none;
  border-radius:0;
  border: 1px solid #707070;
  padding: .75em 2.5em;
  font-size: 14px;
  cursor: ${props => (!props.disabled ? `pointer` : `not-allowed`)};
`

const CustomContactFormText = styled.textarea`
  width: calc(100% - 60px);
  border: none;
  background: none;
  padding: 0;
  margin: 30px;
  font-size: 14px;
  letter-spacing: .06em;
  color: #222;
  font-family: soleil, sans-serif;
  font-weight: 300;
  font-style: normal;
  border-bottom: 1px solid #CCC;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
  &::placeholder {
    font-style: italic;
    color: #CCC;
  }
  @media (min-width: 768px) {
    width: 100%;
    border-bottom: none;
  }  
`

const ContactTextField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid #CCC;
  font-size: 14px;
  letter-spacing: .06em;
  color: #222;
  font-family: soleil, sans-serif;
  font-weight: 300;
  font-style: normal;
  margin: 30px;
  width: calc(100% - 60px);

  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
  &::placeholder {
    font-style: italic;
    color: #CCC;
  }
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }  
`



const ContactPublic = ({ user }) => {

  const [subscribed, setSubscribed] = useState(false);
  const [contactDisabled, setContactDisabled] = useState(true)
  const [text, setText] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState()

  useEffect(() => {
    if (text.length > 5 && validateEmail(email)) {
      setContactDisabled(false)
    } else {
      setContactDisabled(true)
    }
  }, [text, email])



  const subscribe = async (e) => {

    e.preventDefault()
    setContactDisabled(true)
    setSubscribed(true)
    const message = {
      text: text
    }


    axios.post(`${config.API_URL}/request/contact`, {
      email: email,
      captureType: "prospect",
      //origin: `Complimentary/Custom Site`,
      originSite: `DONEGER|TOBE`,
      originSource: `FOS`,
      originDetail: `Complimentary/Custom Site`,
      message: message
    }).then(function (response) {
      setMessage(`Thank you. We'll meet you in your inbox.`)
    })
  }

  const validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  return (
    <CustomContact>
      <CustomContactText>Interested in more? Tap us to move forward.</CustomContactText>
      <form onSubmit={subscribe}>
        <ContactTextField disabled={subscribed} placeholder="Email" type="email" required value={email} onChange={e => setEmail(e.target.value)} />
        <CustomContactFormText disabled={subscribed} rows="5" required placeholder="Let us know how we can help." value={text} onChange={e => setText(e.target.value)}></CustomContactFormText>
        <ButtonCap>
          <Button disabled={contactDisabled}>Send</Button>
        </ButtonCap>
      </form>
      {message && <CustomContactSent>Got it.<br />We’ll meet you in your inbox to discuss next steps.</CustomContactSent>}
    </CustomContact>
  );
};

export default ContactPublic;