import React from 'react'
import useSectionQuery from '../hooks/useSectionQuery'
import RelatedEntry from '../components/RelatedEntry'

import '../404.css'
import styled from 'styled-components'
import { Link } from "react-router-dom";
//import logo from '../assets/TOBELogo-2020.svg'
//import logo from '../assets/logo-tdg.svg'
import logo from '../assets/structure/logo-donegertobe.svg'

const ErrorMsg = styled.div`
    background-color: #fff;
    display: flex; 
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    padding: 0 15px;
    @media (min-width: 768px) {
        min-height: 50%;
    }    
`

const ErrorRelated = styled.div`
    height: auto;
    padding: 0 15px;
    background-color: #f8f8f8;
    display: flex; 
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        height: 50%;
        max-height: 330px;
    }    
`
const ErrorRelatedItems = styled.div`
    max-width: 1008px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 74px;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr; 
    }
    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr 1fr; 
    }
`

const ErrorRelatedItem = styled.div`
    & > h2 {
        font-family: gopher, sans-serif;
        font-weight: 300;
        font-style: normal;
        letter-spacng: .05em;
        line-height: 1.5em;
        margin: 2em 0 .5em;
        font-size: 20px;
        padding-right: 1em;
    }
    & > p {
        letter-spacing: .06em;
        line-height: 1.4em;
        font-family: soleil, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 14px;
        margin: 0 0 3em;
    }        
    &:first-child {
        display: flex; 
        flex-direction: column;
        justify-content: center;
        grid-column-start: 1;
        grid-column-end: 2;
    }
    @media (min-width: 768px) {
        &:first-child {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }
    @media (min-width: 1024px) {
        &:first-child {
            grid-column-start: 1;
            grid-column-end: 2;
        }
        & > p {
            margin: 0 0 1em;
        }
        & > h2 {
            margin: 0 0 1em;
        }
    }
`


const Logo = styled.img`
    width: 200px;
    @media (min-width: 768px) {
        width: 275px;
    }  
    @media (min-width: 1024px) {
        width: 350px;
    }  
`
const ErrorText = styled.h1`
    font-family: gopher, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: .05em;
    line-height: 1.25em;
    margin: 1.5em 0 1em;
    color: #D8292F;
    font-size: 30px;
    @media (min-width: 1024px) {
        line-height: 1.25em;
        font-size: 50px;
    }    
`
const ErrorTextAction = styled(ErrorText)`
    font-size: 17px;
    @media (min-width: 1024px) {
        font-size: 25px;
    }    
`



const Error404 = (props) => {
    document.title = "Page Not Found";
    const pageNumber = 0
    const limit = 3    
    const timeSpan = '2013-01-01'
    const orderBy = '-fields.date'

    const { entries, entriesLoading } = useSectionQuery(`presentation`, pageNumber, limit, timeSpan, orderBy, /* `Great Ideas` */)

    return (
        <div style={{position: "fixed", top: 0, right:0, left: 0, bottom: 0, zIndex: 99999,height: "100vh", display: "flex", flexDirection: "column"}}>
            <ErrorMsg>
                <div>
                    <Logo src={logo}/>
                    <ErrorText>Oh no, we couldn’t find this page.</ErrorText>
                    <ErrorTextAction>Click <Link to="/">here</Link> to be redirected to the DONEGER | TOBE home page.</ErrorTextAction>
                </div>
            </ErrorMsg>
            <ErrorRelated>
                {!entriesLoading && 
                    <ErrorRelatedItems>
                        <ErrorRelatedItem>
                            <h2>Still feeling lost? Let us guide you.</h2>
                            <p>Enjoy these latest reads from the DONEGER | TOBE team.</p>
                        </ErrorRelatedItem>
                        {entries && 
                        entries.items.map((entry, index) => 
                            <RelatedEntry key={index} entry={entry}/>
                        )}
                    </ErrorRelatedItems>
                }
            </ErrorRelated>
        </div>
    );
};

export default Error404;

