import { format } from 'date-fns'

export const formatDate = (date, type) => {
    const [ year, month, day ] = date.substr(0, 10).split('-')
    return format(new Date(
            year,
            (month - 1),
            day,
    ), type)
}

