import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ScrollLock from 'react-scrolllock'
import axios from 'axios'
import closeButton from '../../assets/icon-blackx.svg'
import underline from '../../assets/sched-underline.svg'
import { validateEmail } from '../../utils/validateEmail'
import { config } from '../../utils/constants'
import logo from '../../assets/structure/logo-donegertobe.svg'

const OverlayScreen = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background: rgba(0,0,0,.3);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Modal = styled.div`
    width: 90%;
    max-width: 1150px;
    border: 1px solid #222;
    height: auto;
    background: white;
    z-index: 99;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    @media (min-width: 600px) {
        width: 100%;
    
    }
`

const ModalClose = styled.div`
    position: absolute;
    right: 25px;
    top: 16px;
    z-index: 100;
    font-family: soleil, sans-serif;
    font-size: 9px;
    letter-spacing: .08em;
    line-height: 1em;
    cursor: pointer;
    @media (min-width: 768px) {
        font-size: 13px;
        top: 26px;
        right: unset;
        left: 44px;
    }
`

const CloseButton = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
`

/* const ModalTitle = styled.h1`
    font-family: gopher, sans-serif;
    text-transform: uppercase;      
    letter-spacing: 0.05em;
    font-size: 35px;
    color: #D8292F;
    font-style: italic;
    width: 100%;
    border-bottom: 1px solid #222;
    line-height: 1em;
    text-align: left;
    padding: 1em 44px;
    margin-bottom: 38px;
    font-weight: bold;
` */

const ModalTitle = styled.div`
    
    padding: 40px 25px 0px;
    margin-bottom: 38px;
    font-family: gopher, sans-serif;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: .06em;
    line-height: 1.47em;
    @media (min-width: 768px) {
        padding: 80px 44px 0px;
        font-size: 35px;
        line-height: 1.25em;
        & br {
            display: none;
        }
    }
    & img {
        max-width: 260px;
    }
    
`

const ModalBody = styled.div`
 padding: 0 25px;
 @media (min-width: 768px) {
    padding: 0 44px;
 }
`


const ModalColumns = styled.div`
    
    @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 44px;
    }
`

const FormTextarea = styled.textarea`
  width: 100%;
  border: none;
  background: none;
  padding: .75em 0;
  margin: 0;
  font-size: 15px;
  letter-spacing: .06em;
  color: #222;
  font-family: soleil, sans-serif;
  font-weight: 300;
  font-style: normal;
  border-bottom: 1px solid #707070;
  xborder-top: 1px solid #707070;
  border-radius: 0;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: .6;
  }
  &::placeholder {
    color: #707070;
  }
  @media (min-width: 768px) {
    width: 100%;

  }  
`

const FormText = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid #707070;
  font-size: 15px;
  line-height: 2.5em;
  letter-spacing: .06em;
  color: #222;
  font-family: soleil, sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 0;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: .6;
  }
  &::placeholder {
    color: #707070;
  }
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }  
`

const ModalButton = styled.div`
    margin: 2em 0 2.5em;
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
    justify-content: flex-start;
    @media (min-width: 768px) {
        padding-right: 44px;
        justify-content: flex-end;
    }  
`

const xxxxSubmitButton = styled.button`
    cursor: pointer;

    color: #D8292F;
    text-transform: uppercase;
    font-family: gopher, sans-serif;
    font-weight: 400;
    font-style: normal;
   
    letter-spacing: .06em;
    font-size: 17px;
   border: 1px solid #D8292F;
   border-radius: 18px;
    background-color: #fff;
    padding: .5em 1em;
    opacity: 1
    transition: opacity 150ms;
    margin: 0;
    &:disabled {
        opacity: .7!important;
        cursor: no-drop;
    }
    &:hover {
        opacity: .7
    }
    &:focus {
        outline: none;
    }
`;

const SubmitButton = styled.button`
    cursor: pointer;
    color: #D8292F;
    text-transform: uppercase;
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: .08em;
    font-size: 10px;
    border: 1px solid #D8292F;
    border-radius: 18px;
    background-color: #fff;
    padding: .5em 2em;
    margin: 0;
    &:disabled {
        opacity: .7!important;
        cursor: no-drop;
    }
    &:hover:enabled {
        transition: all 250ms;
        background: #D8292F;
        color: #FFF;
    }

    &:focus {
        outline: none;
    }
    @media (min-width: 768px) {
        font-size: 14px;
        padding: .5em 3em;
    }
`;

const ModalSuccess = styled.div`
    position: absolute;
    background: #fff;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalSuccessTitle = styled.h1`
    font-family: gopher, sans-serif;
    text-transform: uppercase;      
    letter-spacing: 0.05em;
    font-size: 40px;
    color: #D8292F;
    text-align: center;
    line-height: 1.7em;
`

const ModalSuccessText = styled.p`
    font-family: gopher, sans-serif;   
    letter-spacing: 0.05em;
    font-size: 26px;
    text-align: center;
    margin: 0;
`

const ModalSubtitle = styled.div`
    
    display: none;
    @media (min-width: 768px) {
        display: block;
        margin: 1em 0;
        text-transform: uppercase;
        font-family: soleil, sans-serif;
        letter-spacing: .06em;
        line-height: 1em;
    }
`

//const ContactModal = ({open, setOpen, captureType, origin, title, successMsg="We’ll meet you in your inbox to schedule a time."}) => {
const ContactModal = ({ open, setOpen, captureType, originSource, originDetail, title, subtitle="How can we help you?", successMsg = "We’ll meet you in your inbox to schedule a time." }) => {
    const modal = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)
        return () => {
            document.removeEventListener("mousedown", handleClick)
        };
    }, []);

    const handleClick = e => {
        if (modal.current.contains(e.target)) {
            return;
        }
        handleClose()
    };

    const handleClose = (e) => {
        document.removeEventListener("mousedown", handleClick)
        //setIsLocked(false)
        setOpen(false)
    }

    const [name, setName] = useState(``)
    const [email, setEmail] = useState(``)
    const [company, setCompany] = useState(``)
    const [text, setText] = useState(``)
    const [confirmation, setConfirmation] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (name && text && validateEmail(email)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [name, text, email])


    const submitForm = async (e) => {
        //e.preventDefault()
        //setContactDisabled(true)
        //setSubscribed(true)
        setIsDisabled(true)
        setIsLoading(true)
        const message = {
            text: text
        }
        /* if (name) message.name = name
        if (company) message.company = company; */

        const captureObject = {
            email: email,
            captureType: captureType,
            //origin: origin,
            originSite: `DONEGER|TOBE`,
            originSource: originSource,
            originDetail: originDetail,
            message: message
        }
        if (name) captureObject.name = name
        if (company) captureObject.company = company;

        axios.post(`${config.API_URL}/request/contact`, captureObject).then(function (response) {
            setConfirmation(true)
        })
    }



    return (


        <OverlayScreen>
            <ScrollLock isActive={open} accountForScrollbars={true}></ScrollLock>
            <Modal ref={modal}>
                {confirmation &&
                    <ModalSuccess>
                        <div>
                            <ModalSuccessTitle>Thanks!</ModalSuccessTitle>
                            <ModalSuccessText>{successMsg}</ModalSuccessText>
                        </div>
                    </ModalSuccess>
                }


                <ModalTitle>{title}</ModalTitle>
                {/* <ModalTitle><img src={logo} alt="DONEGER | TOBE" /></ModalTitle> */}
                <ModalBody>
                    <ModalSubtitle>Contact Info</ModalSubtitle>
                    <FormText disabled={isLoading} placeholder="Name" type="text" value={name} onChange={e => setName(e.target.value)} />
                    <ModalColumns>
                        <FormText disabled={isLoading} placeholder="Email" type="email" required value={email} onChange={e => setEmail(e.target.value)} />
                        <FormText disabled={isLoading} placeholder="Company" type="text" required value={company} onChange={e => setCompany(e.target.value)} />
                    </ModalColumns>
                    <ModalSubtitle>{subtitle}</ModalSubtitle>
                    <FormTextarea disabled={isLoading} rows="4" placeholder="Message" value={text} onChange={e => setText(e.target.value)} />
                </ModalBody>
                <ModalButton>
                    <SubmitButton disabled={isDisabled} onClick={() => submitForm()}>Submit</SubmitButton>
                </ModalButton>

                {/* <ModalClose><CloseButton onClick={() => handleClose()} src={closeButton} /></ModalClose> */}
                <ModalClose onClick={() => handleClose()}>x close</ModalClose>
            </Modal>
        </OverlayScreen>

    );
};

export default ContactModal;