import { useEffect, useState } from 'react'
import axios from 'axios'
import { config } from '../utils/constants'
import { useAuth0 } from "../react-auth0-spa";

export default function useSectionQuery(query, pageNumber, limit, timeSpan, orderBy, section, tags, endDate) {
  const [entriesLoading, setEntriesLoading] = useState(true)
  const [entries, setEntries] = useState({});
  const { getTokenSilently } = useAuth0()
  const [prevTags, setPrevTags] = useState([]);
  //const [prevEndDate, setPrevEndDate] = useState(endDate);


  useEffect(() => {
    setEntries([])
  }, [query])

  /* useEffect(()=>{
    if(tags && tags !== prevTags){
      setPrevTags(tags)
    }
  },[prevTags, tags]) */

  useEffect(() => {

    setEntriesLoading(true)

    const skippedEntries = pageNumber * limit;

    let queryObject = { content_type: `presentation`, limit: limit, skip: skippedEntries, order: orderBy, 'fields.date[gte]': timeSpan, 'fields.section[in]': section }
    if (endDate) {
      queryObject = { ...queryObject, 'fields.date[lte]': endDate }
      //if(endDate !== prevEndDate){
      setPrevTags(endDate)
      setEntries({})
      //}
    }



    const fetchData = async () => {
      const token = await getTokenSilently()

      if (tags) {
        queryObject = { ...queryObject, 'fields.tags[in]': tags.join() }

      }

      await axios.get(`${config.API_URL}/content/getList`, {
        params: queryObject,
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(function (response) {

          setEntries(prevEntries => {
            const prevItems = prevEntries.items ? prevEntries.items : []

            return { total: response.data.total, currentItems: response.data.items.length, items: [...prevItems, ...response.data.items] }
          })

          setEntriesLoading(false)

        })
        .catch(function (error) {
        });
    }
    fetchData()
  }, [query, pageNumber, limit, timeSpan, tags, orderBy, section, endDate])

  return { entriesLoading, entries }
}

