// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"
import axios from 'axios'
import { config } from './utils/constants'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  //const [bookmarks, setBookmarks] = useState([])
  const [clientInfo, setClientInfo] = useState({})
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [clientList, setClientList] = useState([])



  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook);

      let applicationState = {}

      if (window.location.search.includes("code=") &&
        window.location.search.includes("state=")) {

        const { appState } = await auth0FromHook.handleRedirectCallback()

        // REMOVE IF NO REDIR TO PRIV REQ
        applicationState = appState?.targetUrl ? appState : {targetUrl: "/"}
//applicationState = appState 
        // RESTORE IF ERRORS
        onRedirectCallback(appState);
      }

      const isAuth0Authenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuth0Authenticated)

      if (isAuth0Authenticated) {
        setIsAuthenticated(isAuth0Authenticated)
        const user = await auth0FromHook.getUser();

        const clientName = user[`https://tobereport/`].company
        setUser(user);
    

        const token = await auth0FromHook.getTokenSilently()

        await axios.get(`${config.API_URL}/clients/${clientName}`, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(function (response) {
          const joinYear = new Date(response.data.joinDate).getFullYear()
          setClientInfo({ ...response.data, joinYear })
          return response.data  // REMOVE IF NO REDIR TO PRIV REQ
        }).then(function async(clientData) {  // REMOVE IF NO REDIR TO PRIV REQ
          if(clientData?.micro === true && applicationState.targetUrl === "/" && user[`https://tobereport/`].memberships?.microRedirect){
            //const xState = {targetUrl: "/private"}
            const xState = {targetUrl: "/custom/"+clientData.microPath}
            //console.log(`xxxxx HAS MICRO xxxxx`)
            onRedirectCallback(xState); 
          } 



        }) 

//console.log(applicationState)


      }
      setLoading(false);
    };
    initAuth0();

  }, []);


  const loginWithPopup = async (params = {}) => {
    console.log(`loginWithPopup params`)
    console.log(params)
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleUpdateClientInfo = async (stats) => {
    if (!clientInfo.ideas) {
      const updatedClientInfo = { ...clientInfo, ...stats }
      setClientInfo(updatedClientInfo)
    }
  }

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };


  const handleSetClientList = (list) => {
    setClientList(list)
  }

/*   const loginWithRedirect = async (...p) => {
    alert(`loginWithRedirect`)
    console.log(`loginWithRedirect`)
    console.log(p)
    auth0Client.loginWithRedirect(...p)
  }
 */
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        //bookmarks,
        clientInfo,
        loading,
        popupOpen,
        clientList,
        //pageSizes,
        loginWithPopup,
        handleRedirectCallback,
        //handleUpdateBookmarks,
        //handleRemoveBookmark,
        handleSetClientList,
        handleUpdateClientInfo,
        //handlePageSizeChange,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        //loginWithRedirect,
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};


/* 

const handleRemoveBookmark____XXXX = async (id) => {
  const token = await auth0Client.getTokenSilently()
  const user2 = await auth0Client.getUser();
  const payload = await axios.get(`${config.API_URL}/users/${user2.sub}`, {
    headers: { Authorization: `Bearer ${token}` }
  }).then(function (response) {  
    return response.data.bookmarks
  })
  const newBookmarks = payload.filter(entry=>entry !== id)
  await setBookmarks(newBookmarks)
  await axios.patch(`${config.API_URL}/users/${user2.sub}`, {      
    username: user2.sub,
    bookmarks: newBookmarks
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
};

const handleUpdateBookmarks____XXXX = async (id) => {
  const token = await auth0Client.getTokenSilently()
  const user2 = await auth0Client.getUser();
  const payload = await axios.get(`${config.API_URL}/users/${user2.sub}`, {
    headers: { Authorization: `Bearer ${token}` }
  }).then(function (response) {
    return response.data.bookmarks
  })
  const newBookmarks = [id, ...payload]
  await setBookmarks(newBookmarks)
  await axios.patch(`${config.API_URL}/users/${user2.sub}`, {   
    username: user2.sub,
    bookmarks: newBookmarks
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
};

 */

        //old bookamrks init
        /* await axios.get(`${config.API_URL}/users/${user.sub}`, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(function (response) {  
          setBookmarks(response.data.bookmarks)
        })
        .catch(function (error) {
            axios.post(`${config.API_URL}/users/`, {username: user.sub}, {
              headers: { Authorization: `Bearer ${token}` }
            })
        });  */