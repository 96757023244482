import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";

const usePageTrackingGA4 = () => {

    const location = useLocation();
    const { user } = useAuth0();

    useEffect(() => {
        if ((user?.email.includes("@doneger.com") || user?.email.includes("@tobereport.com"))
            || (location.pathname === "/great-ideas" && !user)
            || (window.location.hostname === `localhost`)
            || (location.pathname === "/creative-direction")) {
            //do nothing
        }
        else {
            window.gtag("event", "page_view", {
                page_path: location.pathname + location.search + location.hash,
                page_search: location.search,
                page_hash: location.hash,
            });
        }
    }, [location]);
};

export default usePageTrackingGA4;