import React from 'react';
import styled from 'styled-components';

const Loading = styled.div` 
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:3;
    background: #fff;
    & h1 {
        color: rgb(216, 41, 47);
        text-transform: uppercase;
        font-family: gopher, sans-serif;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 0.06em;
        font-size: 20px;
    }
`;  

const Loader = () => {

    return (
        <Loading>
{/*             <h1>Loading</h1> */}
        </Loading>
    );
};

export default Loader;

