// src/index.js

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Auth0Provider } from "./react-auth0-spa";
import { BookmarksProvider } from "./contexts/bookmarks-context"; 
import config from "./auth_config.json";
import history from "./utils/history";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {

  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
 
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      audience="https://api.donegertobe"
      scope="offline_access"
    >
      <BookmarksProvider>
        <App />
      </BookmarksProvider>
    </Auth0Provider>
,
  document.getElementById("root")
);

