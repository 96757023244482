import React, { useState, useEffect } from "react";
import NewsItem from "./NewsItem";
import styled from "styled-components";
import axios from "axios";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import useInterval from "../../hooks/useInterval";
import { config } from "../../utils/constants";
import usePressQuery from '../../hooks/usePressQuery'
import { PressQuote } from '../../components/home/PressQuote'

/* const newssss = [
    {
        title: `How Luxury Survived The Pandemic`,
        url: `https://www.washingtonpost.com/magazine/2021/11/17/how-luxury-survived-pandemic/`,
        date: `November 17, 2021`,
        source: `The Washington Post Magazine`,
        link: ``,
        quote: `“Wellness is a luxury … the luxury of keeping yourself in good condition.”`,
    },
    {
        title: `At 75, The Doneger Group Stays Agile, Spreads Its Wings`,
        url: `https://assets.tobereport.com/PDF/WWD-11.17.21.pdf`,
        date: `November 17, 2021`,
        source: `WWD`,
        link: ``,
        quote: `“If you have good people, provide a good service and good information, you have a good business.”`,
    },
    {
        title: `The Glory and Chaos of Street Style Returned to New York`,
        url: `https://www.nytimes.com/2021/09/22/style/the-glory-and-chaos-of-street-style-returned-to-new-york.html`,
        date: `September 2021`,
        source: `The New York Times`,
        link: ``,
        quote: `“It feels like <span>diversity, appreciation for differences</span>, and <span>tolerance for new ideas</span> is in the industry’s bloodstream now.”`,
    },
    {
        title: `MAGIC Morphs Into New Normal as Live Show Returns`,
        url: `https://wwd.com/fashion-news/sportswear/magic-morphs-into-new-normal-as-live-show-returns-1234897497//`,
        date: `August 2021`,
        source: `WWD`,
        link: ``,
        quote: `“We’re easing him out of his COVID-19 athleisure wardrobe and introducing his re-entry into the workplace wardrobe with the same features and benefits he has been enjoying.”`,
    },
    {
        title: `Workleisure Outfits Are Defining Our Style in Uncertain Times`,
        url: `https://www.byrdie.com/workleisure-outfits-5196658`,
        date: `August 2021 `,
        source: `Byrdie`,
        link: ``,
        quote: `“A continuous design direction is putting ’the broccoli in the brownie’ if you will. That means working comfortable construction into more polished piece.”`,
    },
    {
        title: `What happened to Fossil Watches`,
        url: `https://www.cnbc.com/video/2021/06/04/how-fossil-plans-to-compete-with-smartwatches-like-apple-and-fitbit.html`,
        date: `June 2021 `,
        source: `CNBC`,
        link: ``,
        quote: `“Watches are one of those product categories that double as a hobby, <span>there’s dedicated communities around it</span>.”`,
    },
    {
        title: `Lines between men’s and women’s fashion are blurring as more retailers embrace gender-fluid style`,
        url: `https://www.cnbc.com/2021/06/09/gender-fluid-fashion-booms-retailers-take-cues-from-streetwear-gen-z.html`,
        date: `June 2021`,
        source: `CNBC`,
        link: ``,
        quote: `“The gender-neutral perspective is still very new to the larger community, which is why I think it’s so surprising to most to see how much this <span>younger generation is sort of defining themselves in this way</span>.”`,
    },
    {
        title: `2021 Fashion and Retail Predictions`,
        url: `https://fashionreverie.com/?p=30734`,
        date: `January 2021`,
        source: `Fashion Reverie`,
        link: ``,
        quote: `“We are projecting a <span>shift</span> in the trend, merchandising, and fashion cadence that is <span>in sync</span> with the <span>consumer</span>, rather than the production calendar, allowing for more <span>responsiveness</span> to the customer and demanding more <span>creativity</span> in curation and presentation.”`,
    },
    {
        title: `What COVID-19 Will Mean for Design Trends in 2021`,
        url: `https://www.architecturaldigest.com/story/what-covid-19-will-mean-for-design-trends-in-2021`,
        date: `December 2020`,
        source: `Architectural Digest`,
        link: ``,
        quote: `“With indoor confinement comes a need for <span>leveled-up lighting</span> to stand in for the sun, maintaining a sense of <span>circadian rhythm</span> and protecting <span>mental health</span>.” `,
    },
    //{title: `What Comes After Sweatpants`, url:`https://www.businessoffashion.com/articles/retail/what-comes-after-sweatpants`, date: `November 2020`, source: `The Business of Fashion`, link: ``,  quote: `“Contemporary brands offer bottoms in <span>polished fabrications</span> such as lightweight wool and cotton poplin—complete with elastic waistbands for <span>added comfort</span>.”`},
    //{title: `Pandemic's Crushing Impact`, url:`https://assets.tobereport.com/PDF/WWD-PandemicsCrushingImpact.pdf`, date: `April 2020`, source: `Women's Wear Daily`, link: ``,  quote: `“Further down the road, <span>expect new categories of accessories</span>—fashion face masks, neck gaiters, balaclavas, bandanas and scarves.”`},
    //{title: `Trend Forecasters on Men’s Gifts to Buy Before They Sell Out`, url:`https://nymag.com/strategist/article/mens-sell-out-holiday-gifts.html`, date: `November 2019`, source: `The New York Times, The Strategist`, link: ``,  quote: `“Everything about this new brand, from the <span>water soluble hang tags</span> (for footprint-free packaging), to the <span>Velcro butt flap</span> (for easy bathroom breaks), <span>feels thoughtful and cool.</span>”`},
    //{title: `Thirsty: Attention-Seeking Tactics`, url:``, date: `August 2019`, source: `Spring Place`, link: ``,  quote: `“Count on cultural and <span>creative fodder</span> to inspire your next <span>big design, communication</span> or <span>strategy initiative.</span>”`}, 
    //{title: `Food Trends`,url:`https://assets.tobereport.com/PDF/Tastemakers-August15-FoodTrends.mp3`, date: `August 2019`, source: `SiriusXM Trendsetters`, link: ``,  quote: `“<span>Wellness</span> goes into <span>ethics, morals</span>, and <span>sustainability</span>. Beyoncé has the greenprint diet where she’s a vegan, so she’s done this for her health, but the spin on this is that it’s “<span>good</span>” because it’s <span>sustainable</span>.”`}, 
    //{title: `Barneys Opens The High End For Pot Accessories`, url:`https://www.cbsnews.com/video/barneys-opens-the-high-end-for-pot-accessories/`, date: `July 2019`, source: `CBS News`, link: ``,  quote: `“<span>Relevance</span> is where the <span>consumer</span> and <span>culture meet</span>. And the legalization of marijuana is a cultural moment syncing up really beautifully with where the <span>consumer’s interests</span> and <span>instincts</span> are <span>right now</span>.”`},
    //{title: `Trend-Spotting in Fashion + Beauty`, url:``, date: `June 2019`, source: `SiriusXM Trendsetters`, link: ``,  quote: `“The larger movements like “<span>casual</span>” are where <span>beauty</span> and <span>fashion</span> meet.”`}
    //{title: ``, url:``, date: ``, source: ``, link: ``,  quote: `“”`},
];
 */
const News = styled.div`
    width: 100%;
    margin: 0 auto 130px;
    display: grid;
    grid-template-columns: none;
    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const NewsPanel = styled.div`
    border: 1px solid #ccc;
    border-bottom: none;
    margin: 0 -1px -1px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    @media (min-width: 1024px) {
        padding: 1.5em 0;
        min-height: 600px;
        border-bottom: 1px solid #ccc;
    }
`;

const NewsPanelQuotes = styled(NewsPanel)`
    min-height: 0px;
    height: 0;
    overflow: hidden;
    border: none;
    @media (min-width: 1024px) {
        height: auto;
        border: 1px solid #ccc;
    }
`;

/* const NewsQuote = styled.div`
    & p {
        color: #d8292f;
        line-height: 1.22em;
        letter-spacing: 0.04em;
        font-family: freight-big-pro, serif;
        font-weight: 300;
        font-style: normal;
        text-align: center;
        font-size: 40px;
        margin: 0;
        padding: 0 1em;
    }
    & p > span {
        font-family: gopher, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 34px;
    }
    & p > strong {
        font-family: gopher, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 34px;
    }
`; */

const PrePayNews = () => {
    /* const [feed, setFeed] = useState([]);
    const [feedDown, setFeedDown] = useState(false); */



    const { press, pressLoading } = usePressQuery()

    /*     useEffect(() => {
            console.log(`news entries`)
            console.log(press)
        }, [press]); */

    //const [news, setNews] = useState();

    /*     useEffect(() => {
            axios.get(`${config.API_URL}/news`).then(function (response) {
                setNews(response.data);
            });
        }, []); */

    const [newsQuote, setNewsQuote] = useState();
    const [pathIndex, setPathIndex] = useState(0);

    const gotoNext = () => {
        const nextIndex = pathIndex + 1;
        setPathIndex(nextIndex > press.length - 1 ? 0 : nextIndex);
    };

    const gotoIndex = (index) => {
        setDelay(4999 + Math.random());
        setPathIndex(index);
    };

    /*     useEffect(() => {
            if (news) {
                setNewsQuote(news[pathIndex].quote);
            }
        }, [pathIndex, news]); */

    useEffect(() => {
        if (press) {
            setNewsQuote(press[pathIndex].fields.text);

        }
    }, [pathIndex, press]);

    const [delay, setDelay] = useState(5000);
    const [isRunning, setIsRunning] = useState(true);

    useInterval(
        () => {
            gotoNext();
        },
        isRunning ? delay : null
    );



    return (
        <News>
            <NewsPanelQuotes>
                {press &&
                    <SwitchTransition>
                        <CSSTransition
                            key={pathIndex}
                            addEndListener={(node, done) =>
                                node.addEventListener("transitionend", done, false)
                            }
                            classNames="newsFade"
                        >

                            <PressQuote text={newsQuote} />
                        </CSSTransition>
                    </SwitchTransition>
                }
            </NewsPanelQuotes>
            <NewsPanel>
                {press && press.map((item, i) => (
                    <NewsItem
                        itemIndex={i}
                        currentIndex={pathIndex}
                        setIsRunning={setIsRunning}
                        gotoIndex={gotoIndex}
                        key={`news-${i}`}
                        item={item}
                    />
                ))}
            </NewsPanel>
        </News>
    );
};

export default PrePayNews;


