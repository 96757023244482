import React from 'react';
import Loader from '../components/Loader'
import FadeIn from "react-fade-in"
import BreadCrumb from '../components/BreadCrumb'
import usePrivateEntryQuery from '../hooks/usePrivateEntryQuery'
import { useHistory } from "react-router-dom";
import Embed from '../components/microsite/Embed';
import MicroArticle from '../components/microsite/MicroArticle';



const PrivateMicroEntry = (props) => {
    
    const history = useHistory();
    const privatePath = props.match.params.privatePath
    const { entry, loading } = usePrivateEntryQuery(privatePath, props.match.params.slug);

    const renderTemplate = (f) => {
        const {template, url, embed} = f.fields;
        switch(template){

            case 'indesign': 
            case 'video': 
                return <Embed entry={entry}  /> 
            case 'article':
                return <MicroArticle entry={entry} privatePath={privatePath}/>

            default:
                return <></>
        }
    }

    return (
        <>
        {loading ? 
            <Loader/>
            :
            <div style={{ maxWidth: "1400px", margin: "-40px auto 40px" }}>
                <FadeIn>
                    <BreadCrumb path={`/custom/${privatePath}`} section={"D|T for " + entry.displayName} title={entry.fields.title} qString={history.location.state?.qString}/>
                    {renderTemplate(entry) }
                </FadeIn>

            </div>
        }    
        </>
    );
};
export default PrivateMicroEntry;

   