import React, { useEffect, useState, createContext, useContext, useRef, useCallback } from 'react';
import { useAuth0 } from "../react-auth0-spa";
import axios from 'axios'
import { config } from '../utils/constants';

export const BookmarksContext = createContext();
export const useBookmarks = () => useContext(BookmarksContext);
export const BookmarksProvider = (props) => {

  const [bookmarks, setBookmarks] = useState()
  const { getTokenSilently, user } = useAuth0()

  const fetchBookmarks = useCallback(async function () {
    const token = await getTokenSilently()
    try {
      const bms = await axios.get(`${config.API_URL}/users/${user.sub}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setBookmarks(bms.data.bookmarks)
    } catch (error) {
      if (error.response) {
        await axios.post(`${config.API_URL}/users/`, { username: user.sub }, {
          headers: { Authorization: `Bearer ${token}` }
        })
        setBookmarks([])
      }
    }
  }, [user, getTokenSilently]);

  const isFetchedRef = useRef(false);
  useEffect(() => {
    if (!isFetchedRef.current && user) {
      isFetchedRef.current = true;
      fetchBookmarks();
    }
  }, [fetchBookmarks, user]);

  const handleUpdateBookmarks = async (id) => {


    const token = await getTokenSilently()
    const newBookmarks = [id, ...bookmarks]
    setBookmarks(newBookmarks)
    const xxx = await axios.patch(`${config.API_URL}/users/${user.sub}`, {
      username: user.sub,
      bookmarks: newBookmarks
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return xxx


  };

  const handleRemoveBookmark = async (id) => {


    const token = await getTokenSilently()
    const newBookmarks = bookmarks.filter(entry => entry !== id)
    setBookmarks(newBookmarks)
    await axios.patch(`${config.API_URL}/users/${user.sub}`, {
      username: user.sub,
      bookmarks: newBookmarks
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
  };

  const { children } = props;

  return (
    <BookmarksContext.Provider value={{
      bookmarks,
      handleUpdateBookmarks,
      handleRemoveBookmark
    }}>
      {children}
    </BookmarksContext.Provider>
  )

}




