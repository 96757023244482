
import React, { useEffect } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { useHistory } from "react-router-dom";


import PrePayWall from '../views/PrePayWall'

const Home = () => {
    const { isAuthenticated } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated) {
            history.push("/home");
        }
    }, [isAuthenticated, history])

    return (
        <div>
            {!isAuthenticated &&
                <PrePayWall />
            }
        </div>
    );
};

export default Home;

